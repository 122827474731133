@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-style: normal;
  line-height: 1;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
dt,
dd,
th,
td,
small,
figcaption,
time {
  line-height: 1.75;
}

img {
  display: block;
}

small,
figcaption,
time {
  display: block;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

hr {
  display: block;
  margin: 1em 0;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px solid #ddd;
}

input,
button,
textarea,
select {
  font: inherit;
}

input:focus,
button:focus,
textarea:focus,
select:focus {
  outline: none;
}

button {
  cursor: pointer;
}

* .sp {
  display: none;
}

body {
  font-family: "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  font-size: 12px;
  color: #000;
  line-height: 1.75;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

.xlTx {
  margin-bottom: 2em;
  font-size: 30px;
}

.lgTx {
  margin-bottom: 2em;
  font-size: 26px;
}

.mdTx {
  margin-bottom: 2em;
  font-size: 24px;
}

.smTx {
  margin-bottom: 1em;
  font-size: 20px;
}

.xsTx {
  margin-bottom: 1em;
  font-size: 18px;
}

.center {
  text-align: center;
}

.fz18 {
  font-size: 18px;
}

a {
  transition: all 0.2s linear;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

table {
  width: 100%;
}

th,
td {
  border: 1px solid #ccc;
  text-align: center;
  padding: 10px;
}

th {
  background: #eee;
}

.flex {
  display: flex;
}

.flexHalf > * {
  flex: 1;
}

.flexHalf > *:first-child {
  margin-right: 60px;
}

.flexHalf img {
  height: auto;
}

.centerImg {
  margin: 0 auto;
  height: auto;
}

.centerBox {
  text-align: center;
}

.inner {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  line-height: 0;
  position: relative;
}

.mediaTx {
  flex: 1;
}

.bdrs {
  border-radius: 1000px;
}

.bdBaseColor {
  background: #fff;
  border: 2px solid #0a4a90;
  color: #0a4a90;
}

.bgBaseColor {
  background: #0a4a90;
  color: #fff;
}

.secTtlBigEn {
  font-size: 20px;
  color: #0a4a90;
  text-align: center;
  margin-bottom: 3em;
}

.secTtlBigEn .en {
  font-size: 41px;
  display: block;
  letter-spacing: 0.15em;
}

.blue {
  color: #0a4a90;
}

.pageHeader {
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.pageHeaderTtl {
  padding: 15px 0;
  font-size: 41px;
  color: #0a4a90;
  text-align: center;
  letter-spacing: 0.1em;
}

.pageHeaderTtl .en {
  font-size: 14px;
  display: block;
  letter-spacing: 0.05em;
}

.breadcrumbs {
  margin-top: 15px;
}

.breadcrumbs * {
  color: #636363;
}

.breadcrumbs .flex {
  align-items: center;
  flex-wrap: wrap;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumbs a span {
  color: #0a4a90;
}

.breadcrumbs .arrow {
  margin: 0 6px;
  font-size: 13px;
  position: relative;
  top: -2px;
}

.page-template-page .breadcrumbs span:nth-child(3) a,
.serviceMain .breadcrumbs span:nth-child(3) a {
  pointer-events: none;
}

.page-template-page .breadcrumbs span:nth-child(3) a span,
.serviceMain .breadcrumbs span:nth-child(3) a span {
  color: #636363;
}

.pageContents > * {
  margin-top: 75px;
}

.rectangularTtl {
  margin-bottom: 37.5px;
  font-size: 24px;
  background: #f3f6f9;
  padding: 0.4em 0.8em;
  letter-spacing: 0.1em;
}

.borderBottomTtl {
  letter-spacing: 0.1em;
  margin-bottom: 37.5px;
  padding-bottom: 10px;
  font-size: 24px;
  border-bottom: 2px solid #0a4a90;
  font-weight: 700;
}

.ttlBlueTable {
  border-top: 1px solid #ccc;
}

.ttlBlueTable dl {
  align-items: center;
  position: relative;
  border-bottom: 1px solid #ccc;
}

.ttlBlueTable dl::before {
  content: "";
  width: 180px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #f3f6f9;
}

.ttlBlueTable dt {
  padding: 20px 0;
  width: 180px;
  position: relative;
  color: #0a4a90;
  text-align: center;
  font-weight: 700;
}

.ttlBlueTable dd {
  padding: 20px;
  flex: 1;
}

.ttlBlueTable iframe {
  width: 100%;
  height: 300px;
  margin-top: 15px;
}

.ttlBlueTable li:not(:last-child) {
  margin-bottom: 30px;
}

.timelineTable {
  position: relative;
  padding: 10px 0;
}

.timelineTable::before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: calc(140px + 50px);
  background: #ccc;
}

.timelineTable dl {
  align-items: center;
  position: relative;
}

.timelineTable dl:not(:last-child) {
  margin-bottom: 50px;
}

.timelineTable dt {
  width: 140px;
  text-align: right;
  margin-right: 100px;
  font-weight: 700;
  color: #0a4a90;
  position: relative;
}

.timelineTable dt::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #0a4a90;
  position: absolute;
  top: calc(50% - 5px);
  left: calc(140px + 46px);
}

.timelineTable dd {
  flex: 1;
}

.txLink {
  color: #008de2;
}

.txLink:hover {
  text-decoration: underline;
}

.pageMenus {
  background: #f3f6f9;
  margin: 50px 0;
  padding: 45px 0;
}

.pageMenus ul {
  flex-wrap: wrap;
  margin: 0 -15px;
}

.pageMenus li {
  width: 50%;
  padding: 0 15px;
}

.pageMenus li:nth-child(n + 3) {
  margin-top: 30px;
}

.pageMenus a {
  font-size: 18px;
  padding: 1.25em 30px 1.25em 10px;
  display: block;
  text-align: center;
  background: #fff;
  border: 2px solid #0a4a90;
  color: #0a4a90;
  position: relative;
}

.pageMenus a:hover {
  background: #0a4a90;
  color: #fff;
}

.pageMenus a:hover::before {
  border-top-color: #fff;
  border-right-color: #fff;
}

.pageMenus a::before {
  content: "";
  width: 10px;
  height: 10px;
  border-top: 2px solid #0a4a90;
  border-right: 2px solid #0a4a90;
  transform: rotate(45deg);
  position: absolute;
  right: 15px;
  top: calc(50% - 4px);
  transition: all 0.2s linear;
}

.pageMenus span {
  display: block;
  font-size: 14px;
}

.pageMenus .current-menu-item a {
  background: #0a4a90;
  color: #fff;
}

.pageMenus .current-menu-item a::before {
  border-top-color: #fff;
  border-right-color: #fff;
}

.mainColumn {
  flex: 1;
  margin-right: 50px;
}

.sidebar {
  width: 250px;
}

.sidebar ul {
  border-top: 2px solid #ccc;
}

.sidebar a {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
}

.sidebar .mediaImg {
  width: 60px;
}

.sidebar .mediaTx {
  margin-left: 20px;
}

.sidebar time {
  font-size: 12px;
  color: #f8b427;
}

.sidebar h6 {
  font-size: 14px;
}

.sidebarTtl {
  font-size: 17px;
  margin-bottom: 15px;
}

#wpPagenavi {
  margin: 75px 0;
}

#wpPagenavi .wp-pagenavi {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#wpPagenavi .wp-pagenavi * {
  border: none;
  width: 36px;
  height: 36px;
  display: block;
  margin: 0 3px;
  text-align: center;
  line-height: 36px;
  padding: 0;
}

#wpPagenavi a:hover {
  background: #0a4a90;
  color: #fff;
}

#wpPagenavi .current {
  background: #0a4a90;
  color: #fff;
}

.emphasisTtl {
  background: url(../img/stripe.png);
  background-size: 8px;
  padding: 0.5em 0.5em 0.5em 90px;
  font-size: 30px;
  font-weight: 700;
  color: #222;
  position: relative;
  margin-bottom: 40px;
}

.emphasisTtl::before {
  content: "";
  width: 50px;
  height: 50px;
  background: url(../img/icon_warning_min.png) no-repeat center;
  background-size: 100% auto;
  position: absolute;
  left: 20px;
  top: calc(50% - 25px);
}

.iconTtl {
  font-size: 24px;
  font-weight: 700;
  padding: 18px 0 18px 80px;
  margin-bottom: 1em;
  background-position: left center;
  background-size: 55px auto;
  background-repeat: no-repeat;
}

.right {
  text-align: right;
}

.scroll img {
  height: auto;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.aligncenter {
  margin: 0 auto;
}

.rectangleBtn {
  text-align: center;
}

.rectangleBtn a {
  padding: 30px 80px;
  display: inline-block;
  background: #37357d;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.rectangleBtn a:hover {
  opacity: 0.7;
}

.rectangleBtn.toContact a {
  background-repeat: no-repeat;
  background-color: #0867cb;
  background-image: url(../img/mail_icon.png);
  background-position: left 100px center;
  background-size: 44px auto;
  padding: 30px 100px 30px 155px;
}

#spSiteHeader {
  display: none;
}

#siteHeader {
  width: 100%;
  height: 72px;
  background: #fff;
  border-bottom: 1px solid #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  align-items: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

#siteHeader .logo {
  height: 72px;
  width: 100px;
  margin: 0 auto 0 20px;
  align-items: center;
}

#siteHeader .logo a:hover {
  opacity: 0.7;
}

#siteHeader #search {
  align-items: center;
  margin-right: 5px;
}

#siteHeader #search .searchBox {
  position: relative;
}

#siteHeader #search .searchTx {
  width: 130px;
  border: none;
  background: #f3f3f3;
  border-radius: 500px;
  padding: 10px 5px;
}

#siteHeader #search .searchBtn {
  width: 14px;
  position: absolute;
  top: 12px;
  right: 7px;
}

#siteHeader nav {
  height: 72px;
}

#siteHeader a {
  letter-spacing: 0;
}

#siteHeader .mainLink {
  height: 100%;
  align-items: center;
  justify-content: center;
}

#siteHeader i {
  width: 20px;
  margin-bottom: 5px;
}

#siteHeader svg {
  fill: #fff;
}

#siteHeader .normal {
  position: relative;
  border-left: 1px solid #f3f3f3;
  transition: all 0.2s linear;
}

#siteHeader .normal:hover {
  background: #0a4a90;
}

#siteHeader .normal:hover > a {
  color: #fff;
}

#siteHeader .normal > a {
  padding: 0 5px;
  font-size: 13px;
}

#siteHeader .normal:hover .subMenu {
  visibility: visible;
  opacity: 1;
}

#siteHeader .service .subMenu {
  width: 480px;
}

#siteHeader .product .subMenu {
  width: 420px;
}

#siteHeader .product .subMenu .flex {
  justify-content: space-between;
}

#siteHeader .contact a,
#siteHeader .login a,
#siteHeader .shop a {
  flex-direction: column;
  color: #fff;
  font-size: 12px;
}

#siteHeader .contact a:hover,
#siteHeader .login a:hover,
#siteHeader .shop a:hover {
  opacity: 0.7;
}

#siteHeader .contact a {
  background: #0f66c5;
  padding: 0 5px;
}

#siteHeader .login a {
  background: #0a4a90;
  padding: 0 5px;
}

#siteHeader .shop a {
  background: #f8b427;
  padding: 0 5px;
}

#siteHeader .subMenu {
  left: 0;
  width: 240px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.9);
  visibility: hidden;
  position: absolute;
  transition: all 0.2s linear;
  opacity: 0;
  z-index: 99999;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
}

#siteHeader .subMenu a {
  font-size: 14px;
  display: block;
  padding: 10px 10px 10px 30px;
  color: #0a4a90;
  position: relative;
}

#siteHeader .subMenu a:hover::before {
  left: 13px;
}

#siteHeader .subMenu a::before {
  content: "";
  width: 6px;
  height: 6px;
  border-top: 2px solid #0a4a90;
  border-right: 2px solid #0a4a90;
  transform: rotate(45deg);
  position: absolute;
  left: 8px;
  top: calc(50% - 4px);
  transition: all 0.2s linear;
}

_:-ms-input-placeholder #siteHeader .logo img, :root #siteHeader .logo img {
  max-width: 100px;
}

_:-ms-input-placeholder #siteHeader #search .searchTx, :root #siteHeader #search .searchTx {
  max-width: 180px;
}

_:-ms-input-placeholder #siteHeader .contact a,
_:-ms-input-placeholder #siteHeader .shop a, :root #siteHeader .contact a,
:root #siteHeader .shop a {
  padding: 0 10px;
}

_:-ms-input-placeholder #siteHeader i, :root #siteHeader i {
  height: 30px;
}

#siteFooter {
  background: #f0f0f0;
  padding-top: 30px;
}

#siteFooter dl {
  margin-right: 20px;
}

#siteFooter a {
  color: #404040;
}

#siteFooter a:hover {
  color: #0a4a90;
}

#siteFooter dt {
  color: #404040;
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 4px;
  border-bottom: 1px solid #a5a5a5;
  margin-bottom: 5px;
}

#siteFooter dd a {
  font-size: 10px;
  position: relative;
  display: block;
  padding-left: 10px;
}

#siteFooter dd a:hover::before {
  border-top-color: #0a4a90;
  border-right-color: #0a4a90;
}

#siteFooter dd a::before {
  content: "";
  width: 3px;
  height: 3px;
  border-top: 1px solid #404040;
  border-right: 1px solid #404040;
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  top: calc(50% - 1px);
  transition: all 0.2s linear;
}

#siteFooter dd.flex ul:not(:last-child) {
  margin-right: 20px;
}

#siteFooter .otherMenu {
  margin-left: auto;
  width: 130px;
}

#siteFooter .otherMenu li:not(:last-child) {
  margin-bottom: 15px;
}

#siteFooter .otherMenu a {
  display: block;
}

#siteFooter .otherMenu a:hover {
  opacity: 0.7;
}

#siteFooter .otherMenu .contact a {
  background: #0a4a90;
}

#siteFooter .otherMenu .shop a {
  background: #f8b427;
}

#siteFooter .otherMenu .contact a,
#siteFooter .otherMenu .shop a {
  color: #fff;
  font-size: 10px;
  text-align: center;
  padding: 8px 0;
}

#siteFooter .otherMenu .other {
  text-align: center;
}

#siteFooter .otherMenu .other span {
  font-size: 10px;
  font-family: "Noto Sans JP", sans-serif;
  letter-spacing: 0;
  transform: scale(0.9);
  transform-origin: center;
  display: block;
  width: 120%;
  margin: 2px 0 0 -10%;
}

#siteFooter small {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 10px;
  background: #0a4a90;
  color: #fff;
  text-align: center;
  margin-top: 75px;
}

#hero {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

#hero .slick-slide {
  margin: 0 15px;
}

#hero .slick-slide a {
  display: block;
}

#hero .slick-slide a:hover {
  opacity: 0.7;
}

#hero .slick-arrow {
  z-index: 9999;
  width: 40px;
  height: 40px;
}

#hero .slick-arrow::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background: #0a4a90;
  display: block;
  opacity: 1;
}

#hero .slick-arrow::after {
  content: "";
  width: 12px;
  height: 12px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  position: absolute;
  top: calc(50% - 6px);
}

#hero .slick-prev {
  left: 3%;
}

#hero .slick-prev::after {
  left: 16px;
  transform: rotate(-135deg);
}

#hero .slick-next {
  right: 3%;
}

#hero .slick-next::after {
  right: 16px;
  transform: rotate(45deg);
}

#news {
  padding: 25px 0 50px;
}

#news a:hover {
  opacity: 0.7;
}

#news h2 {
  font-size: 14px;
  color: #0a4a90;
  font-weight: 700;
  margin-right: 30px;
}

#news dt {
  margin-right: 15px;
}

#news dd a {
  color: #0a4a90;
  border-bottom: 1px solid #0a4a90;
  padding-bottom: 2px;
}

#news .link {
  margin-left: auto;
}

#newProduct .secTtlBigEn {
  margin-bottom: 2em;
}

#newProduct a {
  flex-direction: column;
  align-items: center;
}

#newProduct h3 {
  color: #666;
  margin: 0.25em 0 0.5em;
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
  margin-top: 10px;
}

#newProduct .slick-arrow {
  z-index: 9999;
  width: 40px;
  height: 40px;
  top: 40%;
}

#newProduct .slick-arrow::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background: #0a4a90;
  display: block;
  opacity: 1;
}

#newProduct .slick-arrow::after {
  content: "";
  width: 12px;
  height: 12px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  position: absolute;
  top: calc(50% - 6px);
}

#newProduct .slick-prev {
  left: -20px;
}

#newProduct .slick-prev::after {
  left: 16px;
  transform: rotate(-135deg);
}

#newProduct .slick-next {
  right: -20px;
}

#newProduct .slick-next::after {
  right: 16px;
  transform: rotate(45deg);
}

#newProduct .slick-slide {
  margin: 0 5px;
}

#products {
  padding: 75px 0;
}

#products .tabs {
  position: relative;
}

#products .tabs input[name="tabItem"] {
  display: none;
}

#products .tabs .tabItem {
  cursor: pointer;
  transition: all 0.2s linear;
}

#products .tabs .tabsBtn {
  justify-content: center;
  border-bottom: 1px solid #0a4a90;
}

#products .tabs label {
  background: #f3f3f3;
  color: #888;
  font-size: 18px;
  font-weight: 700;
  padding: 20px 30px;
  margin: 0 8px;
}

#products .tabs label:hover {
  color: #0a4a90;
  background: rgba(10, 74, 144, 0.1);
}

#products .tabs #tab01:checked ~ .tabsBtn [for="tab01"],
#products .tabs #tab02:checked ~ .tabsBtn [for="tab02"],
#products .tabs #tab03:checked ~ .tabsBtn [for="tab03"] {
  background: #fff;
  color: #0a4a90;
  border-top: 5px solid #0a4a90;
  border-right: 1px solid #0a4a90;
  border-left: 1px solid #0a4a90;
  position: relative;
}

#products .tabs #tab01:checked ~ .tabsBtn [for="tab01"]::before,
#products .tabs #tab02:checked ~ .tabsBtn [for="tab02"]::before,
#products .tabs #tab03:checked ~ .tabsBtn [for="tab03"]::before {
  content: "";
  width: 100%;
  height: 3px;
  background: #fff;
  position: absolute;
  bottom: -3px;
  left: 0;
}

#products .tabs #tab01:checked ~ #tab01Content,
#products .tabs #tab02:checked ~ #tab02Content,
#products .tabs #tab03:checked ~ #tab03Content {
  display: block;
  animation: show 0.2s linear;
}

#products a {
  display: block;
}

#products a:hover {
  opacity: 0.7;
}

#products .parentCatsSp {
  display: none;
}

#products .parentCats {
  flex-wrap: wrap;
  justify-content: space-between;
}

#products .parentCats > li {
  width: calc(50% - 30px);
  margin-top: 60px;
}

#products .mediaImg {
  width: 45%;
}

#products .mediaTx {
  flex: 1;
  margin-left: 25px;
}

#products .lgTx {
  line-height: 1.2;
  color: #0a4a90;
  margin-bottom: 0.5em;
}

#products .childCats {
  margin-top: 20px;
  flex-wrap: wrap;
}

#products .childCats li {
  margin: 0 8px 8px 0;
}

#products .childCats a {
  padding: 0.5em 1em;
  font-size: 12px;
}

#service {
  width: 100%;
  overflow: hidden;
  padding: 75px 0;
  background: #f3f6f9;
}

#service .serviceSlide .slick-slide {
  transition: all 0.2s linear;
  transform: scale(0.85);
  opacity: 0.5;
}

#service .serviceSlide .slick-slide img {
  width: 100%;
  height: auto;
}

#service .serviceSlide .slick-current {
  transform: scale(1);
  opacity: 1;
}

#service .serviceSlide .slick-arrow {
  z-index: 9999;
  width: 40px;
  height: 40px;
}

#service .serviceSlide .slick-arrow::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background: #0a4a90;
  display: block;
  opacity: 1;
}

#service .serviceSlide .slick-arrow::after {
  content: "";
  width: 12px;
  height: 12px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  position: absolute;
  top: calc(50% - 6px);
}

#service .serviceSlide .slick-prev {
  left: 3%;
}

#service .serviceSlide .slick-prev::after {
  left: 16px;
  transform: rotate(-135deg);
}

#service .serviceSlide .slick-next {
  right: 3%;
}

#service .serviceSlide .slick-next::after {
  right: 16px;
  transform: rotate(45deg);
}

#service a {
  display: block;
}

#service a:hover {
  opacity: 0.7;
}

#service .cardCat {
  background: #f8b427;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  position: relative;
  padding: 0 8px;
}

#service .cardCat::before {
  content: "";
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top: 20px solid #f8b427;
  transform: rotate(45deg);
  position: absolute;
  top: 8px;
  right: -20px;
}

#service .cardImg {
  position: relative;
  top: -1px;
}

#service .cardTx {
  padding: 25px;
  background: #0a4a90;
}

#service h3,
#service .desc {
  color: #fff;
}

#service h3 {
  text-align: center;
  font-weight: 700;
  line-height: 1.3;
  font-size: 18px;
  margin-bottom: 1.5em;
}

#service .bdBaseColor {
  width: 178px;
  padding: 4px 0;
  margin: 50px auto 0;
  text-align: center;
  position: relative;
}

#service .bdBaseColor::before {
  content: "";
  width: 8px;
  height: 8px;
  border-top: 2px solid #0a4a90;
  border-right: 2px solid #0a4a90;
  transform: rotate(45deg);
  position: absolute;
  right: 22px;
  top: calc(50% - 4px);
  transition: all 0.2s linear;
}

#service01Contents .rectangleBtnBlue a {
  background: #0a4a90;
}

#service01Contents .rectangleBtn {
  margin-top: 40px;
}

#service01Contents .rectangleBtn a {
  display: block;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

#service05Contents .wp-image-665 {
  padding: 30px 50px;
}

#service05Contents .txFlex {
  margin: 20px 0 60px;
  min-height: auto;
}

#service05Contents .txFlex dt {
  min-height: 72px;
}

#service05Contents .processing02 .type {
  margin: 20px 0;
}

#service05Contents .processing02 .type li {
  flex: 1;
  border: 1px solid #ccc;
}

#service05Contents .processing02 .type li + li {
  border-left: none;
}

#service05Contents .processing02 .type dt {
  padding: 15px 0;
  background: #f3f6f9;
  color: #0a4a90;
  font-weight: bold;
  text-align: center;
}

#service05Contents .processing02 .type dd {
  padding: 30px 0;
}

#service05Contents .processingSample {
  margin-top: 40px;
}

#service05Contents .processingSample .media {
  align-items: center;
  padding: 0 40px 40px;
}

#service05Contents .processingSample .mediaTx {
  margin-left: 40px;
}

#service05Contents .processingSample .mediaTx p + P {
  margin-top: 20px;
}

#service05Contents .processingSample span {
  font-weight: bold;
}

#service05Contents .table.pc {
  padding: 0 30px;
}

#service05Contents th {
  background: #f3f6f9;
}

#service05Contents .bdbn {
  border-bottom: none;
}

#service05Contents .bdrn {
  border-right: none;
}

#service05Contents .bdtDashed {
  border-top: 1px dashed #ccc;
}

#service05Contents .bdbDashed {
  border-bottom: 1px dashed #ccc;
}

#service05Contents .bdrDashed {
  border-right: 1px dashed #ccc;
}

#service05Contents .bdlDashed {
  border-left: 1px dashed #ccc;
}

#service05Contents .img {
  border-left: none;
}

#service05Contents .talignleft {
  text-align: left;
  padding-left: 20px;
}

#service05Contents .flow {
  margin-top: 60px;
}

#service05Contents .flow h6 {
  text-align: center;
  font-weight: bold;
  font-size: 19px;
}

#service05Contents .flow ol {
  padding: 0 30px;
  margin-top: 30px;
}

#service05Contents .flow li + li {
  margin-top: 25px;
}

#service05Contents .flow li + li .mediaImg::before {
  content: "";
  width: 13px;
  height: 24px;
  background: url(../img/caret-right-solid.svg) no-repeat center;
  background-size: 100% auto;
  transform: rotate(90deg);
  position: absolute;
  left: calc(50% - 12px);
  top: -24px;
}

#service05Contents .flow .mediaImg {
  width: 440px;
  align-items: center;
  background: #f3f6f9;
  justify-content: space-between;
  padding-left: 40px;
  position: relative;
}

#service05Contents .flow span {
  display: block;
}

#service05Contents .flow .num {
  text-align: center;
  display: block;
  color: #0a4a90;
  font-size: 24px;
}

#service05Contents .flow .tx {
  font-weight: bold;
  font-size: 19px;
  color: #0a4a90;
  line-height: 1.75;
  text-align: center;
}

#service05Contents .flow .img {
  width: 200px;
}

#service05Contents .flow .mediaTx {
  padding: 20px 0 0 30px;
}

#service05Contents .flow .rectangleBtn a {
  background-color: #0867cb;
  background-image: url(../img/mail_icon.png);
  background-position: left 100px center;
  background-size: 44px auto;
  padding: 30px 100px 30px 155px;
}

#contact {
  padding: 75px 0;
}

#contact .contactList {
  justify-content: center;
}

#contact li {
  width: 200px;
  height: 200px;
  border: 3px solid #0a4a90;
  margin: 0 35px;
  padding: 20px 0;
  text-align: center;
}

#contact i {
  width: 40px;
  display: block;
  margin: 0 auto;
}

#contact svg {
  fill: #4c4c4c;
}

#contact a:hover {
  opacity: 0.7;
}

#contact a[href^="tel:"] {
  color: #0a4a90;
  font-weight: 700;
  font-size: 22px;
  font-family: "Noto Sans JP", sans-serif;
  display: inline-block;
  margin: 10px 0;
}

#contact .btn {
  display: inline-block;
  font-size: 11px;
  line-height: 1.2;
  padding: 1em 3em;
  margin: 20px 0;
}

#contact p {
  line-height: 1.5;
  font-size: 12px;
  letter-spacing: 0;
}

.aboutMagtecHeader {
  background-image: url(../img/header_about_magtec_min.jpg);
}

#overviewContents .simotecGroup .ttlBlueTable {
  margin-top: 15px;
}

#overviewContents .simotecGroup .ttlBlueTable dl::before {
  width: 200px;
}

#overviewContents .simotecGroup .ttlBlueTable dt {
  width: 200px;
}

#csrContents .inner > section {
  margin-top: 75px;
}

#csrContents .inner > section section {
  margin-top: 40px;
}

#csrContents .inner .csrContentsSystem {
  margin-top: 40px;
}

#csrContents .inner .csrContentsSystem h5 {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 25px;
}

#csrContents .inner .csrContentsSystem ul {
  justify-content: center;
  margin-bottom: 20px;
}

#csrContents .inner .csrContentsSystem li {
  margin: 0 15px;
  width: 200px;
  height: 200px;
  border-radius: 200px;
  text-align: center;
  padding-top: 20px;
}

#csrContents .inner .csrContentsSystem li:first-child {
  background: #f3f6f9;
}

#csrContents .inner .csrContentsSystem li:nth-child(2) {
  background: #eefcf8;
}

#csrContents .inner .csrContentsSystem li:last-child {
  background: #fafaec;
}

#csrContents .inner .csrContentsSystem li * {
  font-weight: bold;
  color: #0a4a90;
}

#csrContents .inner .csrContentsSystem li dt {
  font-size: 30px;
}

#csrContents .inner .iso .mediaTx {
  margin-right: 75px;
}

#csrContents .inner .iso .mediaImg {
  width: 250px;
}

#csrContents .inner .iso h5 {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 0.5em;
}

#csrContents .inner .iso .philosophy h5 {
  text-align: center;
}

#csrContents .inner .iso .management img {
  margin: 30px auto 0;
  max-width: 725px;
  width: 100%;
}

#csrContents .inner .environment h5 {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 0.5em;
}

#csrContents .inner .environment p.blue {
  margin-top: 30px;
}

#csrContents .inner .environment .rule .ttlBlueTable {
  margin-top: 20px;
}

#csrContents .inner .environment .rule .ttlBlueTable dl {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

#csrContents .inner .environment .rule .ttlBlueTable dl::before {
  border-right: 1px solid #ccc;
}

#csrContents .inner .case .flexHalf {
  border: 1px solid #ccc;
}

#csrContents .inner .case dl:first-child {
  border-right: 1px solid #ccc;
  margin-right: 0;
}

#csrContents .inner .case dt {
  background: #f3f6f9;
  color: #0a4a90;
  text-align: center;
  font-weight: bold;
  padding: 20px 0;
}

#csrContents .inner .case dd {
  padding: 20px 40px;
}

#recruitContents .rectangleBtn {
  margin-top: 40px;
}

#recruitContents .rectangleBtn a {
  padding: 30px 120px;
  background: #0a4a90;
}

.serviceMain .serviceHeader {
  background-image: url(../img/header_service_min.jpg);
}

.serviceMain .orderLink {
  justify-content: space-between;
}

.serviceMain .orderLink li {
  width: calc(100% / 3 - 15px);
}

.serviceMain .orderLink a {
  display: block;
  color: #fff;
  background: #f8b427;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  padding: 20px 0;
  line-height: 1.5;
}

.serviceMain .orderLink a:hover {
  opacity: 0.7;
}

.serviceMain .support .txFlex {
  margin: 60px 0 30px;
}

.serviceMain .support .txFlex > dt {
  width: 250px;
  background: #e8edf3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0a4a90;
  font-size: 19px;
  font-weight: 700;
}

.serviceMain .support .txFlex > dd {
  flex: 1;
  background: #f3f6f9;
  padding: 20px 25px;
}

.serviceMain .support .txFlex dl:first-of-type {
  margin-top: 20px;
}

.serviceMain .support .txFlex dl dt {
  color: #0a4a90;
  width: 220px;
}

.serviceMain .support .txFlex dl dd {
  flex: 1;
}

.serviceMain .support .imgFlex {
  justify-content: space-between;
}

.serviceMain .support .imgFlex:last-of-type::after {
  width: calc(100% / 3 - 20px);
  content: "";
}

.serviceMain .support .imgFlex li {
  width: calc(100% / 3 - 20px);
}

.serviceMain .support .imgFlex img {
  height: auto;
}

.serviceMain .support .outro {
  margin-top: 75px;
}

.serviceMain .support .rectangleBtn a {
  background-image: url(../img/blank_link.png);
  background-repeat: no-repeat;
  background-position: right 12px bottom 12px;
  background-size: 27px auto;
}

.serviceMain .order .txFlex {
  margin: 60px 0 30px;
}

.serviceMain .order .txFlex > dt {
  width: 250px;
  background: #e8edf3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0a4a90;
  font-size: 19px;
  font-weight: 700;
}

.serviceMain .order .txFlex > dd {
  flex: 1;
  background: #f3f6f9;
  padding: 20px 25px;
}

.serviceMain .order .txFlex dl:first-of-type {
  margin-top: 20px;
}

.serviceMain .order .txFlex dl dt {
  color: #0a4a90;
  width: 220px;
}

.serviceMain .order .txFlex dl dd {
  flex: 1;
}

.serviceMain .order .imgFlex {
  justify-content: space-between;
}

.serviceMain .order .imgFlex:first-of-type::after {
  width: calc(100% / 3 - 20px);
  content: "";
}

.serviceMain .order .imgFlex li {
  width: calc(100% / 3 - 20px);
}

.serviceMain .order .imgFlex img {
  height: auto;
}

.serviceMain .order .wp-image-430 {
  width: 100%;
  max-width: 800px;
  margin: 50px auto 75px;
  height: auto;
}

.serviceMain .order .rectangleBtn a {
  background-image: url(../img/blank_link.png);
  background-repeat: no-repeat;
  background-position: right 12px bottom 12px;
  background-size: 27px auto;
}

.serviceMain .processing .txFlex {
  margin: 60px 0 30px;
  align-items: center;
  min-height: 120px;
  background: #f3f6f9;
}

.serviceMain .processing .txFlex > dt {
  text-align: center;
  min-height: 120px;
  width: 250px;
  background: #e8edf3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0a4a90;
  font-size: 19px;
  font-weight: 700;
}

.serviceMain .processing .txFlex > dd {
  flex: 1;
  padding: 20px 25px;
}

.serviceMain .processing .txFlex dl:first-of-type {
  margin-top: 20px;
}

.serviceMain .processing .txFlex dl dt {
  color: #0a4a90;
  width: 220px;
}

.serviceMain .processing .txFlex dl dd {
  flex: 1;
}

.serviceMain .processing .imgFlex {
  justify-content: space-between;
}

.serviceMain .processing .imgFlex li {
  width: calc(100% / 3 - 20px);
}

.serviceMain .processing .imgFlex img {
  height: auto;
}

.serviceMain .processing .productList dl {
  margin-bottom: 30px;
}

.serviceMain .processing .productList dt {
  color: #0a4a90;
  font-weight: bold;
}

.serviceMain .processing .rectangleBtn {
  margin-top: 60px;
}

.serviceMain .processing .rectangleBtn a {
  background-image: url(../img/blank_link.png);
  background-repeat: no-repeat;
  background-position: right 12px bottom 12px;
  background-size: 27px auto;
  background-color: #f8b427;
  padding: 20px 180px;
}

.serviceMain .processing .img {
  justify-content: center;
}

.serviceMain .processing .img .img01 {
  width: 310px;
  margin-right: 30px;
}

.serviceMain .processing .img .img02 {
  width: 655px;
}

.serviceMain .processing .img img {
  height: auto;
}

.serviceMain .magneticApplicationProducts .txFlex {
  margin: 60px 0 30px;
  background: #f3f6f9;
}

.serviceMain .magneticApplicationProducts .txFlex > dt {
  text-align: center;
  width: 250px;
  background: #e8edf3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0a4a90;
  font-size: 19px;
  font-weight: 700;
}

.serviceMain .magneticApplicationProducts .txFlex > dd {
  flex: 1;
  padding: 20px 25px;
}

.serviceMain .magneticApplicationProducts .txFlex dl:first-of-type {
  margin-top: 20px;
}

.serviceMain .magneticApplicationProducts .txFlex dl dt {
  color: #0a4a90;
  width: 220px;
}

.serviceMain .magneticApplicationProducts .txFlex dl dd {
  flex: 1;
}

.serviceMain .magneticApplicationProducts .imgFlex {
  justify-content: space-between;
}

.serviceMain .magneticApplicationProducts .imgFlex li {
  width: calc(100% / 3 - 20px);
}

.serviceMain .magneticApplicationProducts .imgFlex img {
  height: auto;
}

.serviceMain .magneticApplicationProducts .rectangleBtn {
  margin-top: 60px;
}

.serviceMain .magneticApplicationProducts .rectangleBtn a {
  background-image: url(../img/blank_link.png);
  background-repeat: no-repeat;
  background-position: right 12px bottom 12px;
  background-size: 27px auto;
  background-color: #f8b427;
  padding: 20px 180px;
}

.serviceMain .magneticApplicationProducts .flow {
  margin-top: 75px;
}

.serviceMain .magneticApplicationProducts .flow h4 {
  text-align: center;
  font-weight: bold;
  font-size: 19px;
}

.serviceMain .magneticApplicationProducts .flow ol {
  margin-top: 30px;
}

.serviceMain .magneticApplicationProducts .flow li {
  background: #f3f6f9;
  padding: 40px 0;
  flex-direction: column;
  flex: 1;
  align-items: center;
  position: relative;
}

.serviceMain .magneticApplicationProducts .flow li + li {
  margin-left: 20px;
}

.serviceMain .magneticApplicationProducts .flow li + li::before {
  content: "";
  width: 13px;
  height: 24px;
  background: url(../img/caret-right-solid.svg) no-repeat center;
  background-size: 100% auto;
  position: absolute;
  top: calc(50% - 12px);
  left: -15px;
}

.serviceMain .magneticApplicationProducts .flow li p {
  text-align: center;
  color: #0a4a90;
  font-weight: bold;
  font-size: 19px;
}

.serviceMain .magneticApplicationProducts .flow .num {
  text-align: center;
  display: block;
  color: #0a4a90;
  font-size: 24px;
}

.serviceMain .magneticApplicationProducts .flow .img {
  height: 40px;
  margin: 20px 0;
}

.serviceMain .magneticApplicationProducts .flow svg {
  height: 100%;
  display: inline-block;
}

.serviceMain .magneticApplicationProducts .flow .rectangleBtn a {
  background-color: #0867cb;
  background-image: url(../img/mail_icon.png);
  background-position: left 100px center;
  background-size: 44px auto;
  padding: 30px 100px 30px 155px;
}

.serviceMain .prototypingProcessing .imgFlex {
  margin: 30px 0 60px;
  justify-content: space-between;
}

.serviceMain .prototypingProcessing .imgFlex li {
  width: calc(100% / 3 - 20px);
}

.serviceMain .prototypingProcessing .imgFlex img {
  height: auto;
}

.serviceMain .prototypingProcessing section section {
  margin-bottom: 40px;
}

.serviceMain .prototypingProcessing section section > p {
  padding: 0 40px;
}

.serviceMain .prototypingProcessing h5 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0.75em;
  padding: 0.4em 40px;
}

.serviceMain .prototypingProcessing .rectangleBtn {
  margin-top: 60px;
}

.serviceMain .prototypingProcessing .rectangleBtn a {
  background-image: url(../img/blank_link.png);
  background-repeat: no-repeat;
  background-position: right 12px bottom 12px;
  background-size: 27px auto;
  background-color: #f8b427;
  padding: 20px 180px;
}

.serviceMain .prototypingProcessing .flow {
  margin-top: 40px;
}

.serviceMain .prototypingProcessing .flow h6 {
  text-align: center;
  font-weight: bold;
  font-size: 19px;
}

.serviceMain .prototypingProcessing .flow ol {
  padding: 0 60px;
  margin-top: 30px;
}

.serviceMain .prototypingProcessing .flow li {
  background: #f3f6f9;
  padding: 40px 0;
  flex-direction: column;
  flex: 1;
  align-items: center;
  position: relative;
}

.serviceMain .prototypingProcessing .flow li + li {
  margin-left: 20px;
}

.serviceMain .prototypingProcessing .flow li + li::before {
  content: "";
  width: 13px;
  height: 24px;
  background: url(../img/caret-right-solid.svg) no-repeat center;
  background-size: 100% auto;
  position: absolute;
  top: calc(50% - 12px);
  left: -15px;
}

.serviceMain .prototypingProcessing .flow li p {
  text-align: center;
  color: #0a4a90;
  font-weight: bold;
  font-size: 19px;
}

.serviceMain .prototypingProcessing .flow .num {
  text-align: center;
  display: block;
  color: #0a4a90;
  font-size: 24px;
}

.serviceMain .prototypingProcessing .flow .img {
  height: 40px;
  margin: 20px 0;
}

.serviceMain .prototypingProcessing .flow svg {
  height: 100%;
  display: inline-block;
}

.serviceMain .prototypingProcessing .flow .rectangleBtn a {
  background-color: #0867cb;
  background-image: url(../img/mail_icon.png);
  background-position: left 100px center;
  background-size: 44px auto;
  padding: 30px 100px 30px 155px;
}

.guideHeader {
  background-image: url(../img/header_guide_min.jpg);
}

#faqContents .faqNav li {
  flex: 1;
  flex-basis: 25%;
}

#faqContents .faqNav li:not(:last-child) {
  margin-right: 30px;
}

#faqContents .faqNav a {
  background: #0a4a90;
  color: #fff;
  text-align: center;
  position: relative;
  padding: 30px 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#faqContents .faqNav a::before {
  content: "";
  width: 12px;
  height: 12px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(135deg);
  position: absolute;
  bottom: 15px;
  left: calc(50% - 7px);
  transition: all 0.2s linear;
}

#faqContents .faqNav a:hover {
  opacity: 0.7;
}

#faqContents .faqNav span {
  line-height: 1.2;
  font-size: 16px;
}

#faqContents .faq section {
  padding-top: 40px;
}

#faqContents .faq section h4 {
  margin-bottom: 20px;
}

#faqContents .faq section li:not(:last-child) {
  margin-bottom: 20px;
}

#faqContents .faq section dl {
  border: 1px solid #ccc;
  position: relative;
}

#faqContents .faq section dl::before {
  content: "Q";
  color: #0a4a90;
  font-weight: 700;
  font-size: 21px;
  position: absolute;
  left: 30px;
  top: 20px;
  z-index: 1;
}

#faqContents .faq section dt {
  background: #f3f6f9;
  color: #0a4a90;
  font-size: 16px;
  font-weight: 700;
  padding: 15px 60px 15px 80px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s linear;
}

#faqContents .faq section dt:hover {
  opacity: 0.7;
}

#faqContents .faq section dt::before {
  content: "";
  width: 20px;
  height: 2px;
  background: #0a4a90;
  position: absolute;
  top: 27px;
  right: 20px;
}

#faqContents .faq section dt::after {
  content: "";
  width: 2px;
  height: 20px;
  background: #0a4a90;
  position: absolute;
  top: 18px;
  right: 29px;
  transition: all 0.2s linear;
}

#faqContents .faq section .active::after {
  opacity: 0;
}

#faqContents .faq section dd {
  padding: 20px 20px 20px 80px;
  display: none;
}

#technicalHeader {
  background-image: url(../img/header_technical_min.jpg);
}

#technicalContents .postList a {
  padding: 30px 0;
}

#technicalContents .postList a:hover {
  opacity: 0.7;
}

#technicalContents .postList li {
  border-bottom: 1px solid #ccc;
}

#technicalContents .postList li:first-child a {
  padding-top: 0;
}

#technicalContents .postList .mediaImg {
  width: 150px;
}

#technicalContents .postList .mediaTx {
  margin-left: 40px;
}

#technicalContents .postList h3 {
  color: #0a4a90;
  margin-bottom: 10px;
  font-size: 23px;
}

#technicalContents .postList .more {
  display: inline-block;
  padding: 0.3em 3em;
  margin-top: 20px;
  position: relative;
}

#technicalContents .postList .more::before {
  content: "";
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  position: absolute;
  right: 20px;
  top: calc(50% - 4px);
  transition: all 0.2s linear;
}

.technicalEntryPost {
  padding-bottom: 40px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 40px;
}

.technicalEntryPost .entryHeader {
  margin-bottom: 30px;
}

.technicalEntryPost .entryHeader .lgTx {
  font-weight: 700;
  color: #0a4a90;
  border-bottom: 2px solid #ccc;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
}

.technicalEntryPost .entryHeader time {
  color: #f8b427;
  font-weight: 700;
}

.technicalEntryPost .entry > section {
  margin-top: 40px;
}

.technicalEntryPost .entry h1,
.technicalEntryPost .entry h2,
.technicalEntryPost .entry h3,
.technicalEntryPost .entry h4,
.technicalEntryPost .entry h5,
.technicalEntryPost .entry h6 {
  font-size: 24px;
  color: #0a4a90;
  background: #f3f6f9;
  padding: 10px 20px;
  margin-bottom: 1em;
}

.technicalEntryPost .entry p + p {
  margin-top: 30px;
}

.technicalEntryPost .entry img {
  height: auto;
}

.technicalEntryPost .entry .alignleft {
  margin: 0 40px 20px 0;
}

.previousNext {
  margin-bottom: 75px;
}

.previousNext a {
  font-size: 18px;
  display: block;
  text-align: center;
  position: relative;
  padding: 20px 0;
}

.previousNext ul li {
  flex: 1;
  margin: 0 15px;
}

.previousNext ul a {
  border: 2px solid #0a4a90;
  color: #0a4a90;
}

.previousNext ul a:hover {
  background: #0a4a90;
  color: #fff;
}

.previousNext ul a:hover::before {
  border-top-color: #fff;
  border-right-color: #fff;
}

.previousNext .previous a::before {
  content: "";
  width: 8px;
  height: 8px;
  border-top: 2px solid #0a4a90;
  border-right: 2px solid #0a4a90;
  transform: rotate(-135deg);
  position: absolute;
  left: 15px;
  top: calc(50% - 4px);
  transition: all 0.2s linear;
}

.previousNext .next a::before {
  content: "";
  width: 8px;
  height: 8px;
  border-top: 2px solid #0a4a90;
  border-right: 2px solid #0a4a90;
  transform: rotate(45deg);
  position: absolute;
  right: 15px;
  top: calc(50% - 4px);
  transition: all 0.2s linear;
}

.previousNext .back {
  margin: 30px auto 0;
  width: 80%;
  max-width: 500px;
}

.previousNext .back:hover {
  opacity: 0.7;
}

.previousNext .back::before {
  content: "";
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(-135deg);
  position: absolute;
  left: 15px;
  top: calc(50% - 4px);
  transition: all 0.2s linear;
}

#productHeader {
  background-image: url(../img/header_products_min.jpg);
}

#productContents {
  padding-top: 60px;
}

#productContents #newProduct {
  margin-top: 20px;
}

#productContents #products {
  margin-top: 0;
}

#productContents .productLink {
  background: #f8b427;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  width: 80%;
  max-width: 500px;
  margin: 0 auto 60px;
  display: block;
  text-align: center;
  line-height: 1.4;
  padding: 20px 0;
  position: relative;
}

#productContents .productLink:hover {
  opacity: 0.7;
}

#productContents .productLink::before {
  content: "";
  width: 27px;
  height: 27px;
  background: url(../img/blank.png) no-repeat center bottom;
  position: absolute;
  right: 12px;
  bottom: 12px;
  background-size: 27px auto;
}

.productEntryPost {
  margin-top: -50px;
}

.productEntryPost .entryHeader h3 {
  text-align: center;
  font-size: 36px;
  color: #0a4a90;
  letter-spacing: 0.1em;
  margin: 0.5em 0;
  line-height: 1.2;
}

.productEntryPost .entry section {
  margin-bottom: 60px;
}

.productEntryPost .entry h1,
.productEntryPost .entry h2,
.productEntryPost .entry h3,
.productEntryPost .entry h4,
.productEntryPost .entry h5,
.productEntryPost .entry h6 {
  font-weight: 500;
  margin-bottom: 1em;
}

.productEntryPost .entry p:empty {
  display: none;
}

.productEntryPost .entry p + p {
  margin-top: 2em;
}

.productEntryPost .entry table {
  margin-bottom: 20px;
}

.productEntryPost .entry th,
.productEntryPost .entry td {
  color: #0a4a90;
}

.productEntryPost .entry th {
  font-weight: normal;
  background: #f3f6f9;
}

.productEntryPost img {
  height: auto;
}

.productTaxonomyDesc {
  align-items: center;
  margin-top: 0;
}

.productTaxonomyDesc .tx {
  text-align: center;
}

.productTaxonomyDesc h3 {
  font-size: 28px;
  letter-spacing: 0.1em;
  margin-bottom: 0.5em;
}

.taxonomyEntryList {
  flex-wrap: wrap;
  margin: 0 -20px 40px;
  text-align: center;
}

.taxonomyEntryList li {
  padding: 0 20px;
  width: calc(100% / 3);
  margin-bottom: 40px;
}

.taxonomyEntryList a {
  display: block;
}

.taxonomyEntryList a:hover {
  opacity: 0.7;
}

.taxonomyEntryList h4 {
  font-size: 24px;
  margin: 0.25em 0 0.5em;
}

.postid-466 .entryHeader,
.postid-469 .entryHeader,
.postid-477 .entryHeader {
  display: none;
}

.postid-469 .card {
  text-align: center;
  margin-bottom: 60px;
}

.postid-469 .card img {
  height: auto;
}

#productsContents section section {
  margin: 40px 0 80px;
}

#productsContents .warning li {
  background: #f3f6f9;
  flex: 1;
  padding: 25px;
  flex-direction: column;
  align-items: center;
}

#productsContents .warning li:not(:last-child) {
  margin-right: 30px;
}

#productsContents .warning img {
  width: 150px;
  height: auto;
  margin-bottom: 30px;
}

#productsContents .attention section:nth-of-type(1) .iconTtl {
  background-image: url(../img/icon_attention01.png);
}

#productsContents .attention section:nth-of-type(2) .iconTtl {
  background-image: url(../img/icon_attention02.png);
}

#productsContents .attention section:nth-of-type(3) .iconTtl {
  background-image: url(../img/icon_attention03.png);
}

#productsContents .attention section:nth-of-type(4) .iconTtl {
  background-image: url(../img/icon_attention04.png);
}

#productsContents .attention section:nth-of-type(5) .iconTtl {
  background-image: url(../img/icon_attention05.png);
}

#productsContents .attention ol {
  margin-left: 80px;
  counter-reset: number;
}

#productsContents .attention ol > li {
  position: relative;
  padding-left: 40px;
  counter-increment: number;
}

#productsContents .attention ol > li:not(:last-child) {
  margin-bottom: 40px;
}

#productsContents .attention ol > li::before {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background: #f3f6f9;
  color: #0a4a90;
  position: absolute;
  left: 0;
  top: 0;
  content: counter(number);
  text-align: center;
  font-weight: 700;
  line-height: 25px;
}

#productsContents .attention ol ul {
  margin: 20px 0 0 40px;
}

#aircargoContents section section {
  margin-top: 80px;
}

#aircargoContents .borderBottomTtl {
  font-weight: 700;
}

#aircargoContents p + p {
  margin-top: 30px;
}

#lineupContents .lineupNav {
  margin-top: 40px;
  padding-bottom: 40px;
}

#lineupContents .lineupNav li {
  flex: 1;
  flex-basis: 25%;
}

#lineupContents .lineupNav li:not(:last-child) {
  margin-right: 10px;
}

#lineupContents .lineupNav a {
  background: #0a4a90;
  color: #fff;
  position: relative;
  padding: 30px 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#lineupContents .lineupNav a::before {
  content: "";
  width: 12px;
  height: 12px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(135deg);
  position: absolute;
  bottom: 15px;
  left: calc(50% - 7px);
  transition: all 0.2s linear;
}

#lineupContents .lineupNav a:hover {
  opacity: 0.7;
}

#lineupContents .lineupNav span {
  line-height: 1.2;
  font-size: 16px;
}

#lineupContents section section {
  margin: 40px 0 80px;
}

#lineupContents .borderBottomTtl {
  font-weight: 700;
}

#lineupContents h5 {
  font-weight: 700;
  font-size: 19px;
  margin-bottom: 1em;
}

#lineupContents p + p {
  margin-top: 20px;
}

#lineupContents #lineup01 .mediaImg {
  width: 270px;
}

#lineupContents #lineup01 .mediaImg img {
  height: auto;
}

#lineupContents #lineup01 .mediaTx {
  flex: 1;
  margin-left: 60px;
}

#lineupContents #lineup01 ul {
  margin-top: 40px;
}

#lineupContents #lineup01 li {
  flex-direction: column;
  flex: 1;
}

#lineupContents #lineup01 li:not(:last-child) {
  margin-right: 30px;
}

#lineupContents #lineup01 li > p {
  margin-top: 25px;
}

#lineupContents #lineup01 .img {
  height: 145px;
  border: 1px solid #ccc;
  text-align: center;
  padding-top: 12px;
}

#lineupContents #lineup01 .img img {
  width: auto;
  height: 120px;
  margin: 0 auto;
}

#lineupContents #lineup02 > section:nth-of-type(2) .mediaTx {
  order: 2;
}

#lineupContents #lineup02 > section:nth-of-type(2) .mediaImg {
  order: 1;
}

#lineupContents #lineup02 .flexHalf > * {
  flex: 1;
}

#lineupContents #lineup02 .flexHalf > *:first-child {
  margin-right: 30px;
}

#lineupContents #lineup02 .flexHalf .mediaTx {
  margin-top: 20px;
}

#lineupContents #lineup02 .tabs {
  margin-top: 40px;
}

#lineupContents #lineup02 .tabs input[name="tabItem"] {
  display: none;
}

#lineupContents #lineup02 .tabs .tabItem {
  cursor: pointer;
  transition: all 0.2s linear;
}

#lineupContents #lineup02 .tabs .tabsBtn {
  justify-content: space-between;
}

#lineupContents #lineup02 .tabs label {
  flex: 1;
  color: #0a4a90;
  font-size: 16px;
  font-weight: 700;
  padding: 20px 0;
  border: 2px solid #0a4a90;
  text-align: center;
}

#lineupContents #lineup02 .tabs label:not(:last-child) {
  margin-right: 10px;
}

#lineupContents #lineup02 .tabs label:hover {
  color: #fff;
  background: #0a4a90;
}

#lineupContents #lineup02 .tabs #tab01:checked ~ .tabsBtn [for="tab01"],
#lineupContents #lineup02 .tabs #tab02:checked ~ .tabsBtn [for="tab02"],
#lineupContents #lineup02 .tabs #tab03:checked ~ .tabsBtn [for="tab03"],
#lineupContents #lineup02 .tabs #tab04:checked ~ .tabsBtn [for="tab04"],
#lineupContents #lineup02 .tabs #tab05:checked ~ .tabsBtn [for="tab05"] {
  background: #0a4a90;
  color: #fff;
}

#lineupContents #lineup02 .tabs .tabContent {
  display: none;
}

#lineupContents #lineup02 .tabs .tabContent > p {
  margin: 30px 0 15px;
}

#lineupContents #lineup02 .tabs #tab01:checked ~ #tab01Content,
#lineupContents #lineup02 .tabs #tab02:checked ~ #tab02Content,
#lineupContents #lineup02 .tabs #tab03:checked ~ #tab03Content,
#lineupContents #lineup02 .tabs #tab04:checked ~ #tab04Content,
#lineupContents #lineup02 .tabs #tab05:checked ~ #tab05Content {
  display: block;
  animation: show 0.2s linear;
}

#lineupContents #lineup02 .tabs tr:nth-child(odd) {
  background: #f3f6f9;
}

#lineupContents #lineup02 .tabs .txLink {
  color: #0a4a90;
}

#lineupContents #lineup02 .tabs th {
  font-size: 16px;
  color: #636363;
  line-height: 1.2;
}

#lineupContents #lineup02 .tabs td {
  padding: 15px;
  color: #636363;
}

#lineupContents #lineup02 .tabs .taLeft {
  text-align: left;
}

#lineupContents #lineup03 img {
  width: 100%;
  max-width: 800px;
  height: auto;
  margin: 40px auto;
}

#lineupContents #lineup04 .scroll,
#lineupContents #lineup05 .scroll {
  margin: 40px 0 0;
}

#lineupContents #lineup04 th,
#lineupContents #lineup04 td,
#lineupContents #lineup05 th,
#lineupContents #lineup05 td {
  padding: 15px 10px;
}

#lineupContents #lineup04 th,
#lineupContents #lineup05 th {
  background: #f3f6f9;
  color: #0a4a90;
}

#lineupContents #lineup04 td,
#lineupContents #lineup05 td {
  color: #636363;
}

#lineupContents #lineup04 td:first-child,
#lineupContents #lineup05 td:first-child {
  font-weight: 700;
}

#lineupContents #lineup05 .scroll {
  margin-bottom: 40px;
}

#searchHeader {
  background: #f3f6f9;
}

#searchContents .inner {
  margin-top: 25px;
}

#searchContents .searchResults {
  color: #0a4a90;
}

#searchContents .parentheses:first-child {
  position: relative;
  top: -5px;
  left: -3px;
}

#searchContents .bold {
  font-size: 18px;
  font-weight: 700;
}

#searchContents dl {
  padding: 40px 0;
  border-bottom: 2px solid #ddd;
}

#searchContents dt a {
  font-size: 24px;
  color: #0a4a90;
}

#searchContents dt a:hover {
  text-decoration: underline;
}

#searchContents dd {
  line-height: 1.4;
  margin-top: 10px;
}

#newsHeader {
  background-image: url(../img/header_news_min.jpg);
}

#newsContents .inner {
  margin-top: 0;
}

#newsContents .postList li {
  border-bottom: 1px solid #ccc;
}

#newsContents .postList a {
  padding: 30px 0 35px;
  display: block;
}

#newsContents .postList a:hover {
  opacity: 0.7;
}

#newsContents .postList time {
  color: #f8b427;
  font-weight: 700;
}

#newsContents .postList h3 {
  font-size: 24px;
  color: #0a4a90;
  margin: 0.25em 0 0.5em;
}

#newsContents .postList .more {
  display: inline-block;
  padding: 0.3em 3em;
  margin-top: 20px;
  position: relative;
}

#newsContents .postList .more::before {
  content: "";
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  position: absolute;
  right: 20px;
  top: calc(50% - 4px);
  transition: all 0.2s linear;
}

#newsContents .newsEntryPost {
  margin-top: 30px;
}

#newsContents .newsEntryPost time {
  color: #f8b427;
  font-weight: 700;
}

#newsContents .newsEntryPost h3 {
  font-weight: 700;
  font-size: 30px;
  color: #0a4a90;
  margin: 0.2em 0 0.5em;
  padding-bottom: 0.75em;
  border-bottom: 2px solid #ccc;
}

#newsContents .entry > section {
  margin-top: 40px;
}

#newsContents .entry h1,
#newsContents .entry h2,
#newsContents .entry h3,
#newsContents .entry h4,
#newsContents .entry h5,
#newsContents .entry h6 {
  font-size: 24px;
  color: #0a4a90;
  background: #f3f6f9;
  padding: 10px 20px;
  margin-bottom: 1em;
}

#newsContents .entry p + p {
  margin-top: 30px;
}

#newsContents .entry img {
  height: auto;
}

#newsContents .entry .alignleft {
  margin: 0 40px 20px 0;
}

#newsContents .previousNext {
  margin-top: 80px;
  padding-top: 40px;
  border-top: 1px solid #ccc;
}

#applyContents .inner {
  max-width: 750px;
  margin-bottom: 90px;
}

#applyContents form dl {
  margin-bottom: 15px;
}

#applyContents form dt {
  width: 240px;
}

#applyContents form dd {
  flex: 1;
}

#applyContents form input,
#applyContents form textarea {
  border: 1px solid #ccc;
  padding: 0.75em;
  width: 100%;
  border-radius: 3px;
  transition: all 0.2s linear;
}

#applyContents form input:focus,
#applyContents form textarea:focus {
  border: 1px solid #0a4a90;
}

#applyContents form .ttlBlueLineTable + div {
  text-align: center;
}

#applyContents form .contact-btn,
#applyContents form .wpcf7c-btn-back {
  width: auto;
  padding: 30px 80px;
  display: inline-block;
  background: #37357d;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  transition: all 0.2s linear;
  border: none;
  cursor: pointer;
  border-radius: 0;
  margin-top: 20px;
}

#applyContents form .contact-btn:hover,
#applyContents form .wpcf7c-btn-back:hover {
  opacity: 0.7;
}

#applyContents .wpcf7-response-output {
  border-color: #008de2;
  color: #008de2;
  padding: 20px;
  background: rgba(0, 141, 226, 0.1);
}

#applyContents .wpcf7-not-valid-tip {
  margin-top: 5px;
}
