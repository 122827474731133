@charset "utf-8";
@import "_imports/const";

// **************************************************
//	_0_reset
// **************************************************
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-style: normal;
  line-height: 1;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
dt,
dd,
th,
td,
small,
figcaption,
time {
  line-height: 1.75;
}

img {
  display: block;
}

small,
figcaption,
time {
  display: block;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

hr {
  display: block;
  margin: 1em 0;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px solid #ddd;
}

input,
button,
textarea,
select {
  font: inherit;
  &:focus {
    outline: none;
  }
}

button {
  cursor: pointer;
}

// **************************************************
//	_1_common
// **************************************************
* .sp {
  display: none;
}

body {
  font-family: $baseFont;
  font-size: 12px;
  color: $bkColor;
  line-height: 1.75;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

.xlTx {
  margin-bottom: 2em;
  font-size: 30px;
}

.lgTx {
  margin-bottom: 2em;
  font-size: 26px;
}

.mdTx {
  margin-bottom: 2em;
  font-size: 24px;
}

.smTx {
  margin-bottom: 1em;
  font-size: 20px;
}

.xsTx {
  margin-bottom: 1em;
  font-size: 18px;
}

.center {
  text-align: center;
}

.fz18 {
  font-size: 18px;
}

a {
  transition: $ts;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

table {
  width: 100%;
}

th,
td {
  border: 1px solid #ccc;
  text-align: center;
  padding: 10px;
}

th {
  background: #eee;
}

.flex {
  display: flex;
}

.flexHalf {
  > * {
    flex: 1;
    &:first-child {
      margin-right: 60px;
    }
  }
  img {
    height: auto;
  }
}

.centerImg {
  margin: 0 auto;
  height: auto;
}

.centerBox {
  text-align: center;
}

.inner {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  line-height: 0;
  position: relative;
}

.mediaTx {
  flex: 1;
}

.bdrs {
  border-radius: 1000px;
}

.bdBaseColor {
  background: #fff;
  border: 2px solid $baseColor;
  color: $baseColor;
}

.bgBaseColor {
  background: $baseColor;
  color: #fff;
}

.secTtlBigEn {
  font-size: 20px;
  color: $baseColor;
  text-align: center;
  margin-bottom: 3em;
  .en {
    font-size: 41px;
    display: block;
    letter-spacing: 0.15em;
  }
}

.blue {
  color: $baseColor;
}

.pageHeader {
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.pageHeaderTtl {
  padding: 15px 0;
  font-size: 41px;
  color: $baseColor;
  text-align: center;
  letter-spacing: 0.1em;
  .en {
    font-size: 14px;
    display: block;
    letter-spacing: 0.05em;
  }
}

.breadcrumbs {
  margin-top: 15px;
  * {
    color: #636363;
  }
  .flex {
    align-items: center;
    flex-wrap: wrap;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
    span {
      color: $baseColor;
    }
  }
  .arrow {
    margin: 0 6px;
    font-size: 13px;
    position: relative;
    top: -2px;
  }
  .page-template-page &,
  .serviceMain & {
    span {
      &:nth-child(3) {
        a {
          pointer-events: none;
          span {
            color: #636363;
          }
        }
      }
    }
  }
}

.pageContents {
  > * {
    margin-top: $baseSpace;
  }
}

.rectangularTtl {
  margin-bottom: $baseSpace / 2;
  font-size: 24px;
  background: $lightBlueColor;
  padding: 0.4em 0.8em;
  letter-spacing: 0.1em;
}

.borderBottomTtl {
  letter-spacing: 0.1em;
  margin-bottom: $baseSpace / 2;
  padding-bottom: 10px;
  font-size: 24px;
  border-bottom: 2px solid $baseColor;
  font-weight: 700;
}

.ttlBlueTable {
  $w: 180px;
  border-top: 1px solid #ccc;
  dl {
    align-items: center;
    position: relative;
    border-bottom: 1px solid #ccc;
    &::before {
      @include beforeAfter($w, 100%);
      position: absolute;
      top: 0;
      left: 0;
      background: $lightBlueColor;
    }
  }
  dt {
    padding: 20px 0;
    width: $w;
    position: relative;
    color: $baseColor;
    text-align: center;
    font-weight: 700;
  }
  dd {
    padding: 20px;
    flex: 1;
  }
  iframe {
    width: 100%;
    height: 300px;
    margin-top: 15px;
  }
  li {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.timelineTable {
  $w: 140px;
  $spW: 100px;
  position: relative;
  padding: 10px 0;
  &::before {
    @include beforeAfter(1px, 100%);
    position: absolute;
    top: 0;
    left: calc(#{$w} + 50px);
    background: #ccc;
  }
  dl {
    align-items: center;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }
  dt {
    width: $w;
    text-align: right;
    margin-right: 100px;
    font-weight: 700;
    color: $baseColor;
    position: relative;
    &::before {
      @include beforeAfter(10px, 10px);
      border-radius: 10px;
      background: $baseColor;
      position: absolute;
      top: calc(50% - 5px);
      left: calc(#{$w} + 46px);
    }
  }
  dd {
    flex: 1;
  }
}

.txLink {
  color: $vividBlueColor;
  &:hover {
    text-decoration: underline;
  }
}

.pageMenus {
  background: $lightBlueColor;
  margin: $baseSpace / 1.5 0;
  padding: 45px 0;
  ul {
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  li {
    // width: calc(100% / 3);
    width: 50%;
    padding: 0 15px;
    &:nth-child(n + 3) {
      margin-top: 30px;
    }
  }
  @mixin styleChange {
    background: $baseColor;
    color: #fff;
    &::before {
      border-top-color: #fff;
      border-right-color: #fff;
    }
  }
  a {
    font-size: 18px;
    padding: 1.25em 30px 1.25em 10px;
    display: block;
    text-align: center;
    background: #fff;
    border: 2px solid $baseColor;
    color: $baseColor;
    position: relative;
    &:hover {
      @include styleChange;
    }
    &::before {
      @include beforeAfter(10px, 10px);
      border-top: 2px solid $baseColor;
      border-right: 2px solid $baseColor;
      transform: rotate(45deg);
      position: absolute;
      right: 15px;
      top: calc(50% - 4px);
      transition: $ts;
    }
  }
  span {
    display: block;
    font-size: 14px;
  }
  .current-menu-item {
    a {
      @include styleChange;
    }
  }
}

.mainColumn {
  flex: 1;
  margin-right: 50px;
}

.sidebar {
  width: 250px;
  ul {
    border-top: 2px solid #ccc;
  }
  a {
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
  }
  .mediaImg {
    width: 60px;
  }
  .mediaTx {
    margin-left: 20px;
  }
  time {
    font-size: 12px;
    color: $orangeColor;
  }
  h6 {
    font-size: 14px;
  }
}

.sidebarTtl {
  font-size: 17px;
  margin-bottom: 15px;
}

#wpPagenavi {
  margin: $baseSpace 0;
  .wp-pagenavi {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    * {
      border: none;
      width: 36px;
      height: 36px;
      display: block;
      margin: 0 3px;
      text-align: center;
      line-height: 36px;
      padding: 0;
    }
  }
  a {
    &:hover {
      background: $baseColor;
      color: #fff;
    }
  }
  .current {
    background: $baseColor;
    color: #fff;
  }
}

.emphasisTtl {
  background: url(../img/stripe.png);
  background-size: 8px;
  padding: 0.5em 0.5em 0.5em 90px;
  font-size: 30px;
  font-weight: 700;
  color: #222;
  position: relative;
  margin-bottom: 40px;
  &::before {
    @include beforeAfter(50px, 50px);
    background: url(../img/icon_warning_min.png) no-repeat center;
    background-size: 100% auto;
    position: absolute;
    left: 20px;
    top: calc(50% - 25px);
  }
}

.iconTtl {
  font-size: 24px;
  font-weight: 700;
  padding: 18px 0 18px 80px;
  margin-bottom: 1em;
  background-position: left center;
  background-size: 55px auto;
  background-repeat: no-repeat;
}

.right {
  text-align: right;
}

.scroll {
  img {
    height: auto;
  }
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.aligncenter {
  margin: 0 auto;
}

.rectangleBtn {
  text-align: center;
  a {
    padding: 30px 80px;
    display: inline-block;
    background: #37357d;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    &:hover {
      opacity: 0.7;
    }
  }
  &.toContact {
    a {
      background-repeat: no-repeat;
      background-color: #0867cb;
      background-image: url(../img/mail_icon.png);
      background-position: left 100px center;
      background-size: 44px auto;
      padding: 30px 100px 30px 155px;
    }
  }
}

// **************************************************
//	_2_layout
// **************************************************
// **************************************************
//	spSiteHeader
// **************************************************
#spSiteHeader {
  display: none;
}

// **************************************************
//	siteHeader
// **************************************************
#siteHeader {
  width: 100%;
  height: $siteHeaderSize;
  background: #fff;
  border-bottom: 1px solid #fff;
  box-shadow: 0 0 20px rgba(#000, 0.06);
  align-items: center;
  @include boxShadow(1px, 1px, #000, 0.1);
  .logo {
    height: $siteHeaderSize;
    width: 100px;
    margin: 0 auto 0 20px;
    align-items: center;
    a {
      &:hover {
        opacity: 0.7;
      }
    }
  }
  #search {
    align-items: center;
    margin-right: 5px;
    .searchBox {
      position: relative;
    }
    .searchTx {
      width: 130px;
      border: none;
      background: #f3f3f3;
      border-radius: 500px;
      padding: 10px 5px;
    }
    .searchBtn {
      width: 14px;
      position: absolute;
      top: 12px;
      right: 7px;
    }
  }
  nav {
    height: $siteHeaderSize;
  }
  a {
    letter-spacing: 0;
  }
  .mainLink {
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  i {
    width: 20px;
    margin-bottom: 5px;
  }
  svg {
    fill: #fff;
  }
  .normal {
    position: relative;
    border-left: 1px solid #f3f3f3;
    transition: $ts;
    &:hover {
      background: $baseColor;
      > a {
        color: #fff;
      }
    }
    > a {
      padding: 0 5px;
      font-size: 13px;
    }
    &:hover {
      .subMenu {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .service {
    .subMenu {
      width: 480px;
    }
  }
  .product {
    .subMenu {
      width: 420px;
      .flex {
        justify-content: space-between;
      }
    }
  }
  .contact,
  .login,
  .shop {
    a {
      flex-direction: column;
      color: #fff;
      font-size: 12px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .contact {
    a {
      background: $blueColor;
      padding: 0 5px;
    }
  }
  .login {
    a {
      background: $baseColor;
      padding: 0 5px;
    }
  }
  .shop {
    a {
      background: $orangeColor;
      padding: 0 5px;
    }
  }
  .subMenu {
    left: 0;
    width: 240px;
    padding: 10px;
    background: rgba(#fff, 0.9);
    visibility: hidden;
    position: absolute;
    transition: $ts;
    opacity: 0;
    z-index: 99999;
    @include boxShadow(3px, 7px, #000, 0.2);
    a {
      font-size: 14px;
      display: block;
      padding: 10px 10px 10px 30px;
      color: $baseColor;
      position: relative;
      &:hover {
        &::before {
          left: 13px;
        }
      }
      &::before {
        @include beforeAfter(6px, 6px);
        border-top: 2px solid $baseColor;
        border-right: 2px solid $baseColor;
        transform: rotate(45deg);
        position: absolute;
        left: 8px;
        top: calc(50% - 4px);
        transition: $ts;
      }
    }
  }
}
@include ie11 {
  #siteHeader .logo img {
    max-width: 100px;
  }
  #siteHeader #search .searchTx {
    max-width: 180px;
  }
  #siteHeader .contact a,
  #siteHeader .shop a {
    padding: 0 10px;
  }
  #siteHeader i {
    height: 30px;
  }
}

// **************************************************
//	siteFooter
// **************************************************
#siteFooter {
  background: $lightGrayColor;
  padding-top: 30px;
  dl {
    margin-right: 20px;
  }
  a {
    color: #404040;
    &:hover {
      color: $baseColor;
    }
  }
  dt {
    color: #404040;
    font-size: 12px;
    font-weight: 700;
    padding-bottom: 4px;
    border-bottom: 1px solid #a5a5a5;
    margin-bottom: 5px;
  }
  dd {
    a {
      font-size: 10px;
      position: relative;
      display: block;
      padding-left: 10px;
      &:hover {
        &::before {
          border-top-color: $baseColor;
          border-right-color: $baseColor;
        }
      }
      &::before {
        @include beforeAfter(3px, 3px);
        border-top: 1px solid #404040;
        border-right: 1px solid #404040;
        transform: rotate(45deg);
        position: absolute;
        left: 0;
        top: calc(50% - 1px);
        transition: $ts;
      }
    }
    &.flex {
      ul {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
  .otherMenu {
    margin-left: auto;
    width: 130px;
    li {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    a {
      display: block;
      &:hover {
        opacity: 0.7;
      }
    }
    .contact {
      a {
        background: $baseColor;
      }
    }
    .shop {
      a {
        background: $orangeColor;
      }
    }
    .contact,
    .shop {
      a {
        color: #fff;
        font-size: 10px;
        text-align: center;
        padding: 8px 0;
      }
    }
    .other {
      text-align: center;
      span {
        font-size: 10px;
        font-family: $notoFont;
        letter-spacing: 0;
        transform: scale(0.9);
        transform-origin: center;
        display: block;
        width: 120%;
        margin: 2px 0 0 -10%;
      }
    }
  }
  small {
    font-family: $notoFont;
    font-size: 10px;
    background: $baseColor;
    color: #fff;
    text-align: center;
    margin-top: $baseSpace;
  }
}

// **************************************************
//	_3_page
// **************************************************
// index
// **************************************************
//	hero
// **************************************************
#hero {
  width: 100%;
  height: 300px;
  overflow: hidden;
  .slick-slide {
    margin: 0 15px;
    a {
      display: block;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .slick-arrow {
    z-index: 9999;
    width: 40px;
    height: 40px;
    &::before {
      @include beforeAfter(100%, 100%);
      border-radius: 100px;
      background: $baseColor;
      display: block;
      opacity: 1;
    }
    &::after {
      @include beforeAfter(12px, 12px);
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      position: absolute;
      top: calc(50% - 6px);
    }
  }
  .slick-prev {
    left: 3%;
    &::after {
      left: 16px;
      transform: rotate(-135deg);
    }
  }
  .slick-next {
    right: 3%;
    &::after {
      right: 16px;
      transform: rotate(45deg);
    }
  }
}

// **************************************************
//	news
// **************************************************
#news {
  padding: 25px 0 50px;
  a {
    &:hover {
      opacity: 0.7;
    }
  }
  h2 {
    font-size: 14px;
    color: $baseColor;
    font-weight: 700;
    margin-right: 30px;
  }
  dt {
    margin-right: 15px;
  }
  dd {
    a {
      color: $baseColor;
      border-bottom: 1px solid $baseColor;
      padding-bottom: 2px;
    }
  }
  .link {
    margin-left: auto;
  }
}

// **************************************************
//	newProduct
// **************************************************
#newProduct {
  .secTtlBigEn {
    margin-bottom: 2em;
  }
  a {
    flex-direction: column;
    align-items: center;
  }
  h3 {
    color: #666;
    margin: 0.25em 0 0.5em;
    font-size: 16px;
    text-align: center;
    line-height: 1.5;
    margin-top: 10px;
  }
  .slick-arrow {
    z-index: 9999;
    width: 40px;
    height: 40px;
    top: 40%;
    &::before {
      @include beforeAfter(100%, 100%);
      border-radius: 100px;
      background: $baseColor;
      display: block;
      opacity: 1;
    }
    &::after {
      @include beforeAfter(12px, 12px);
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      position: absolute;
      top: calc(50% - 6px);
    }
  }
  .slick-prev {
    left: -20px;
    &::after {
      left: 16px;
      transform: rotate(-135deg);
    }
  }
  .slick-next {
    right: -20px;
    &::after {
      right: 16px;
      transform: rotate(45deg);
    }
  }
  .slick-slide {
    margin: 0 5px;
  }
}

// **************************************************
//	products
// **************************************************
#products {
  padding: $baseSpace 0;
  .tabs {
    position: relative;
    input[name="tabItem"] {
      display: none;
    }
    .tabItem {
      cursor: pointer;
      transition: $ts;
    }
    .tabsBtn {
      justify-content: center;
      border-bottom: 1px solid $baseColor;
    }
    label {
      background: #f3f3f3;
      color: #888;
      font-size: 18px;
      font-weight: 700;
      padding: 20px 30px;
      margin: 0 8px;
      &:hover {
        color: $baseColor;
        background: rgba($baseColor, 0.1);
      }
    }
    #tab01:checked ~ .tabsBtn [for="tab01"],
    #tab02:checked ~ .tabsBtn [for="tab02"],
    #tab03:checked ~ .tabsBtn [for="tab03"] {
      background: #fff;
      color: $baseColor;
      border-top: 5px solid $baseColor;
      border-right: 1px solid $baseColor;
      border-left: 1px solid $baseColor;
      position: relative;
      &::before {
        @include beforeAfter(100%, 3px);
        background: #fff;
        position: absolute;
        bottom: -3px;
        left: 0;
      }
    }
    #tab01:checked ~ #tab01Content,
    #tab02:checked ~ #tab02Content,
    #tab03:checked ~ #tab03Content {
      display: block;
      animation: show 0.2s linear;
    }
  }
  a {
    display: block;
    &:hover {
      opacity: 0.7;
    }
  }
  .parentCatsSp {
    display: none;
  }
  .parentCats {
    flex-wrap: wrap;
    justify-content: space-between;
    > li {
      width: calc(50% - 30px);
      margin-top: 60px;
    }
  }
  .mediaImg {
    width: 45%;
  }
  .mediaTx {
    flex: 1;
    margin-left: 25px;
  }
  .lgTx {
    line-height: 1.2;
    color: $baseColor;
    margin-bottom: 0.5em;
  }
  .childCats {
    margin-top: 20px;
    flex-wrap: wrap;
    li {
      margin: 0 8px 8px 0;
    }
    a {
      padding: 0.5em 1em;
      font-size: 12px;
    }
  }
}

// **************************************************
//	service
// **************************************************
#service {
  width: 100%;
  overflow: hidden;
  padding: $baseSpace 0;
  background: $lightBlueColor;
  .serviceSlide {
    .slick-slide {
      transition: $ts;
      transform: scale(0.85);
      opacity: 0.5;
      img {
        width: 100%;
        height: auto;
      }
    }
    .slick-current {
      transform: scale(1);
      opacity: 1;
    }
    .slick-arrow {
      z-index: 9999;
      width: 40px;
      height: 40px;
      &::before {
        @include beforeAfter(100%, 100%);
        border-radius: 100px;
        background: $baseColor;
        display: block;
        opacity: 1;
      }
      &::after {
        @include beforeAfter(12px, 12px);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        position: absolute;
        top: calc(50% - 6px);
      }
    }
    .slick-prev {
      left: 3%;
      &::after {
        left: 16px;
        transform: rotate(-135deg);
      }
    }
    .slick-next {
      right: 3%;
      &::after {
        right: 16px;
        transform: rotate(45deg);
      }
    }
  }
  a {
    display: block;
    &:hover {
      opacity: 0.7;
    }
  }
  .cardCat {
    background: $orangeColor;
    color: #fff;
    font-size: 12px;
    display: inline-block;
    position: relative;
    padding: 0 8px;
    &::before {
      @include beforeAfter(0, 0);
      border: 20px solid $tp;
      border-top: 20px solid $orangeColor;
      transform: rotate(45deg);
      position: absolute;
      top: 8px;
      right: -20px;
    }
  }
  .cardImg {
    position: relative;
    top: -1px;
  }
  .cardTx {
    padding: 25px;
    background: $baseColor;
  }
  h3,
  .desc {
    color: #fff;
  }
  h3 {
    text-align: center;
    font-weight: 700;
    line-height: 1.3;
    font-size: 18px;
    margin-bottom: 1.5em;
  }
  .bdBaseColor {
    width: 178px;
    padding: 4px 0;
    margin: 50px auto 0;
    text-align: center;
    position: relative;
    &::before {
      @include beforeAfter(8px, 8px);
      border-top: 2px solid $baseColor;
      border-right: 2px solid $baseColor;
      transform: rotate(45deg);
      position: absolute;
      right: 22px;
      top: calc(50% - 4px);
      transition: $ts;
    }
  }
}

#service01Contents {
  .rectangleBtnBlue {
    a {
      background: $baseColor;
    }
  }
  .rectangleBtn {
    margin-top: 40px;
    a {
      display: block;
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
    }
  }
}

#service05Contents {
  .wp-image-665 {
    padding: 30px 50px;
  }
  .txFlex {
    margin: 20px 0 60px;
    min-height: auto;
    dt {
      min-height: 72px;
    }
  }
  .processing02 {
    .type {
      margin: 20px 0;
      li {
        flex: 1;
        border: 1px solid #ccc;
        + li {
          border-left: none;
        }
      }
      dt {
        padding: 15px 0;
        background: $lightBlueColor;
        color: $baseColor;
        font-weight: bold;
        text-align: center;
      }
      dd {
        padding: 30px 0;
      }
    }
  }
  .processingSample {
    margin-top: 40px;
    .media {
      align-items: center;
      padding: 0 40px 40px;
    }
    .mediaTx {
      margin-left: 40px;
      p {
        + P {
          margin-top: 20px;
        }
      }
    }
    span {
      font-weight: bold;
    }
  }
  .table {
    &.pc {
      padding: 0 30px;
    }
  }
  th {
    background: $lightBlueColor;
  }
  .bdbn {
    border-bottom: none;
  }
  .bdrn {
    border-right: none;
  }
  .bdtDashed {
    border-top: 1px dashed #ccc;
  }
  .bdbDashed {
    border-bottom: 1px dashed #ccc;
  }
  .bdrDashed {
    border-right: 1px dashed #ccc;
  }
  .bdlDashed {
    border-left: 1px dashed #ccc;
  }
  .img {
    border-left: none;
  }
  .talignleft {
    text-align: left;
    padding-left: 20px;
  }
  .flow {
    margin-top: 60px;
    h6 {
      text-align: center;
      font-weight: bold;
      font-size: 19px;
    }
    ol {
      padding: 0 30px;
      margin-top: 30px;
    }
    li {
      + li {
        margin-top: 25px;
        .mediaImg {
          &::before {
            @include beforeAfter(13px, 24px);
            background: url(../img/caret-right-solid.svg) no-repeat center;
            background-size: 100% auto;
            transform: rotate(90deg);
            position: absolute;
            left: calc(50% - 12px);
            top: -24px;
          }
        }
      }
    }
    .mediaImg {
      width: 440px;
      align-items: center;
      background: $lightBlueColor;
      justify-content: space-between;
      padding-left: 40px;
      position: relative;
    }
    span {
      display: block;
    }
    .num {
      text-align: center;
      display: block;
      color: $baseColor;
      font-size: 24px;
    }
    .tx {
      font-weight: bold;
      font-size: 19px;
      color: $baseColor;
      line-height: 1.75;
      text-align: center;
    }
    .img {
      width: 200px;
    }
    .mediaTx {
      padding: 20px 0 0 30px;
    }
    .rectangleBtn {
      a {
        background-color: #0867cb;
        background-image: url(../img/mail_icon.png);
        background-position: left 100px center;
        background-size: 44px auto;
        padding: 30px 100px 30px 155px;
      }
    }
  }
}

// **************************************************
//	contact
// **************************************************
#contact {
  padding: $baseSpace 0;
  .contactList {
    justify-content: center;
  }
  li {
    width: 200px;
    height: 200px;
    border: 3px solid $baseColor;
    margin: 0 35px;
    padding: 20px 0;
    text-align: center;
  }
  i {
    width: 40px;
    display: block;
    margin: 0 auto;
  }
  svg {
    fill: #4c4c4c;
  }
  a {
    &:hover {
      opacity: 0.7;
    }
  }
  a[href^="tel:"] {
    color: $baseColor;
    font-weight: 700;
    font-size: 22px;
    font-family: $notoFont;
    display: inline-block;
    margin: 10px 0;
  }
  .btn {
    display: inline-block;
    font-size: 11px;
    line-height: 1.2;
    padding: 1em 3em;
    margin: 20px 0;
  }
  p {
    line-height: 1.5;
    font-size: 12px;
    letter-spacing: 0;
  }
}

// page
// **************************************************
//	about_magtec
// **************************************************
.aboutMagtecHeader {
  background-image: url(../img/header_about_magtec_min.jpg);
}

#overviewContents {
  .simotecGroup {
    .ttlBlueTable {
      margin-top: 15px;
      dl {
        &::before {
          width: 200px;
        }
      }
      dt {
        width: 200px;
      }
    }
  }
}

#csrContents {
  .inner {
    > section {
      margin-top: $baseSpace;
      section {
        margin-top: 40px;
      }
    }
    .csrContentsSystem {
      margin-top: 40px;
      h5 {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 25px;
      }
      ul {
        justify-content: center;
        margin-bottom: 20px;
      }
      li {
        margin: 0 15px;
        width: 200px;
        height: 200px;
        border-radius: 200px;
        text-align: center;
        padding-top: 20px;
        &:first-child {
          background: #f3f6f9;
        }
        &:nth-child(2) {
          background: #eefcf8;
        }
        &:last-child {
          background: #fafaec;
        }
        * {
          font-weight: bold;
          color: $baseColor;
        }
        dt {
          font-size: 30px;
        }
      }
    }
    .iso {
      .mediaTx {
        margin-right: $baseSpace;
      }
      .mediaImg {
        width: 250px;
      }
      h5 {
        font-size: 19px;
        font-weight: bold;
        margin-bottom: 0.5em;
      }
      .philosophy {
        h5 {
          text-align: center;
        }
      }
      .management {
        img {
          margin: 30px auto 0;
          max-width: 725px;
          width: 100%;
        }
      }
    }
    .environment {
      h5 {
        font-size: 19px;
        font-weight: bold;
        margin-bottom: 0.5em;
      }
      p {
        &.blue {
          margin-top: 30px;
        }
      }
      .rule {
        .ttlBlueTable {
          margin-top: 20px;
          dl {
            border-right: 1px solid #ccc;
            border-left: 1px solid #ccc;
            &::before {
              border-right: 1px solid #ccc;
            }
          }
        }
      }
    }
    .case {
      .flexHalf {
        border: 1px solid #ccc;
      }
      dl {
        &:first-child {
          border-right: 1px solid #ccc;
          margin-right: 0;
        }
      }
      dt {
        background: $lightBlueColor;
        color: $baseColor;
        text-align: center;
        font-weight: bold;
        padding: 20px 0;
      }
      dd {
        padding: 20px 40px;
      }
    }
  }
}

#recruitContents {
  .rectangleBtn {
    margin-top: 40px;
    a {
      padding: 30px 120px;
      background: $baseColor;
    }
  }
}

// **************************************************
//	service
// **************************************************
.serviceMain {
  .serviceHeader {
    background-image: url(../img/header_service_min.jpg);
  }
  .orderLink {
    justify-content: space-between;
    li {
      width: calc(100% / 3 - 15px);
    }
    a {
      display: block;
      color: #fff;
      background: $orangeColor;
      font-size: 18px;
      text-align: center;
      font-weight: 700;
      padding: 20px 0;
      line-height: 1.5;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .support {
    .txFlex {
      margin: 60px 0 30px;
      > dt {
        width: 250px;
        background: #e8edf3;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $baseColor;
        font-size: 19px;
        font-weight: 700;
      }
      > dd {
        flex: 1;
        background: $lightBlueColor;
        padding: 20px 25px;
      }
      dl {
        &:first-of-type {
          margin-top: 20px;
        }
        dt {
          color: $baseColor;
          width: 220px;
        }
        dd {
          flex: 1;
        }
      }
    }
    .imgFlex {
      justify-content: space-between;
      &:last-of-type {
        &::after {
          width: calc(100% / 3 - 20px);
          content: "";
        }
      }
      li {
        width: calc(100% / 3 - 20px);
      }
      img {
        height: auto;
      }
    }
    .outro {
      margin-top: $baseSpace;
    }
    .rectangleBtn {
      a {
        background-image: url(../img/blank_link.png);
        background-repeat: no-repeat;
        background-position: right 12px bottom 12px;
        background-size: 27px auto;
      }
    }
  }
  .order {
    .txFlex {
      margin: 60px 0 30px;
      > dt {
        width: 250px;
        background: #e8edf3;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $baseColor;
        font-size: 19px;
        font-weight: 700;
      }
      > dd {
        flex: 1;
        background: $lightBlueColor;
        padding: 20px 25px;
      }
      dl {
        &:first-of-type {
          margin-top: 20px;
        }
        dt {
          color: $baseColor;
          width: 220px;
        }
        dd {
          flex: 1;
        }
      }
    }
    .imgFlex {
      justify-content: space-between;
      &:first-of-type {
        &::after {
          width: calc(100% / 3 - 20px);
          content: "";
        }
      }
      li {
        width: calc(100% / 3 - 20px);
      }
      img {
        height: auto;
      }
    }
    .wp-image-430 {
      width: 100%;
      max-width: 800px;
      margin: 50px auto $baseSpace;
      height: auto;
    }
    .rectangleBtn {
      a {
        background-image: url(../img/blank_link.png);
        background-repeat: no-repeat;
        background-position: right 12px bottom 12px;
        background-size: 27px auto;
      }
    }
  }
  .processing {
    .txFlex {
      margin: 60px 0 30px;
      align-items: center;
      min-height: 120px;
      background: $lightBlueColor;
      > dt {
        text-align: center;
        min-height: 120px;
        width: 250px;
        background: #e8edf3;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $baseColor;
        font-size: 19px;
        font-weight: 700;
      }
      > dd {
        flex: 1;
        padding: 20px 25px;
      }
      dl {
        &:first-of-type {
          margin-top: 20px;
        }
        dt {
          color: $baseColor;
          width: 220px;
        }
        dd {
          flex: 1;
        }
      }
    }
    .imgFlex {
      justify-content: space-between;
      li {
        width: calc(100% / 3 - 20px);
      }
      img {
        height: auto;
      }
    }
    .productList {
      dl {
        margin-bottom: 30px;
      }
      dt {
        color: $baseColor;
        font-weight: bold;
      }
    }
    .rectangleBtn {
      margin-top: 60px;
      a {
        background-image: url(../img/blank_link.png);
        background-repeat: no-repeat;
        background-position: right 12px bottom 12px;
        background-size: 27px auto;
        background-color: $orangeColor;
        padding: 20px 180px;
      }
    }
    .img {
      justify-content: center;
      .img01 {
        width: 310px;
        margin-right: 30px;
      }
      .img02 {
        width: 655px;
      }
      img {
        height: auto;
      }
    }
  }
  .magneticApplicationProducts {
    .txFlex {
      margin: 60px 0 30px;
      background: $lightBlueColor;
      > dt {
        text-align: center;
        width: 250px;
        background: #e8edf3;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $baseColor;
        font-size: 19px;
        font-weight: 700;
      }
      > dd {
        flex: 1;
        padding: 20px 25px;
      }
      dl {
        &:first-of-type {
          margin-top: 20px;
        }
        dt {
          color: $baseColor;
          width: 220px;
        }
        dd {
          flex: 1;
        }
      }
    }
    .imgFlex {
      justify-content: space-between;
      li {
        width: calc(100% / 3 - 20px);
      }
      img {
        height: auto;
      }
    }
    .rectangleBtn {
      margin-top: 60px;
      a {
        background-image: url(../img/blank_link.png);
        background-repeat: no-repeat;
        background-position: right 12px bottom 12px;
        background-size: 27px auto;
        background-color: $orangeColor;
        padding: 20px 180px;
      }
    }
    .flow {
      margin-top: $baseSpace;
      h4 {
        text-align: center;
        font-weight: bold;
        font-size: 19px;
      }
      ol {
        margin-top: 30px;
      }
      li {
        background: $lightBlueColor;
        padding: 40px 0;
        flex-direction: column;
        flex: 1;
        align-items: center;
        position: relative;
        + li {
          margin-left: 20px;
          &::before {
            @include beforeAfter(13px, 24px);
            background: url(../img/caret-right-solid.svg) no-repeat center;
            background-size: 100% auto;
            position: absolute;
            top: calc(50% - 12px);
            left: -15px;
          }
        }
        p {
          text-align: center;
          color: $baseColor;
          font-weight: bold;
          font-size: 19px;
        }
      }
      .num {
        text-align: center;
        display: block;
        color: $baseColor;
        font-size: 24px;
      }
      .img {
        height: 40px;
        margin: 20px 0;
      }
      svg {
        height: 100%;
        display: inline-block;
      }
      .rectangleBtn {
        a {
          background-color: #0867cb;
          background-image: url(../img/mail_icon.png);
          background-position: left 100px center;
          background-size: 44px auto;
          padding: 30px 100px 30px 155px;
        }
      }
    }
  }
  .prototypingProcessing {
    .imgFlex {
      margin: 30px 0 60px;
      justify-content: space-between;
      li {
        width: calc(100% / 3 - 20px);
      }
      img {
        height: auto;
      }
    }
    section {
      section {
        margin-bottom: 40px;
        > p {
          padding: 0 40px;
        }
      }
    }
    h5 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0.75em;
      padding: 0.4em 40px;
    }
    .rectangleBtn {
      margin-top: 60px;
      a {
        background-image: url(../img/blank_link.png);
        background-repeat: no-repeat;
        background-position: right 12px bottom 12px;
        background-size: 27px auto;
        background-color: $orangeColor;
        padding: 20px 180px;
      }
    }
    .flow {
      margin-top: 40px;
      h6 {
        text-align: center;
        font-weight: bold;
        font-size: 19px;
      }
      ol {
        padding: 0 60px;
        margin-top: 30px;
      }
      li {
        background: $lightBlueColor;
        padding: 40px 0;
        flex-direction: column;
        flex: 1;
        align-items: center;
        position: relative;
        + li {
          margin-left: 20px;
          &::before {
            @include beforeAfter(13px, 24px);
            background: url(../img/caret-right-solid.svg) no-repeat center;
            background-size: 100% auto;
            position: absolute;
            top: calc(50% - 12px);
            left: -15px;
          }
        }
        p {
          text-align: center;
          color: $baseColor;
          font-weight: bold;
          font-size: 19px;
        }
      }
      .num {
        text-align: center;
        display: block;
        color: $baseColor;
        font-size: 24px;
      }
      .img {
        height: 40px;
        margin: 20px 0;
      }
      svg {
        height: 100%;
        display: inline-block;
      }
      .rectangleBtn {
        a {
          background-color: #0867cb;
          background-image: url(../img/mail_icon.png);
          background-position: left 100px center;
          background-size: 44px auto;
          padding: 30px 100px 30px 155px;
        }
      }
    }
  }
}

// **************************************************
//	guide
// **************************************************
.guideHeader {
  background-image: url(../img/header_guide_min.jpg);
}

// **************************************************
//	faq
// **************************************************
#faqContents {
  .faqNav {
    > ul {
    }
    li {
      flex: 1;
      flex-basis: 25%;
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
    a {
      background: $baseColor;
      color: #fff;
      text-align: center;
      position: relative;
      padding: 30px 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before {
        @include beforeAfter(12px, 12px);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(135deg);
        position: absolute;
        bottom: 15px;
        left: calc(50% - 7px);
        transition: $ts;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    span {
      line-height: 1.2;
      font-size: 16px;
    }
  }
  .faq {
    section {
      padding-top: 40px;
      h4 {
        margin-bottom: 20px;
      }
      li {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
      dl {
        border: 1px solid #ccc;
        position: relative;
        &::before {
          content: "Q";
          color: $baseColor;
          font-weight: 700;
          font-size: 21px;
          position: absolute;
          left: 30px;
          top: 20px;
          z-index: 1;
        }
      }
      dt {
        background: $lightBlueColor;
        color: $baseColor;
        font-size: 16px;
        font-weight: 700;
        padding: 15px 60px 15px 80px;
        position: relative;
        cursor: pointer;
        transition: $ts;
        &:hover {
          opacity: 0.7;
        }
        &::before {
          @include beforeAfter(20px, 2px);
          background: $baseColor;
          position: absolute;
          top: 27px;
          right: 20px;
        }
        &::after {
          @include beforeAfter(2px, 20px);
          background: $baseColor;
          position: absolute;
          top: 18px;
          right: 29px;
          transition: $ts;
        }
      }
      .active {
        &::after {
          opacity: 0;
        }
      }
      dd {
        padding: 20px 20px 20px 80px;
        display: none;
      }
    }
  }
}

// **************************************************
//	technical
// **************************************************
#technicalHeader {
  background-image: url(../img/header_technical_min.jpg);
}

#technicalContents {
  .postList {
    a {
      padding: 30px 0;
      &:hover {
        opacity: 0.7;
      }
    }
    li {
      border-bottom: 1px solid #ccc;
      &:first-child {
        a {
          padding-top: 0;
        }
      }
    }
    .mediaImg {
      width: 150px;
    }
    .mediaTx {
      margin-left: 40px;
    }
    h3 {
      color: $baseColor;
      margin-bottom: 10px;
      font-size: 23px;
    }
    .more {
      display: inline-block;
      padding: 0.3em 3em;
      margin-top: 20px;
      position: relative;
      &::before {
        @include beforeAfter(8px, 8px);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(45deg);
        position: absolute;
        right: 20px;
        top: calc(50% - 4px);
        transition: $ts;
      }
    }
  }
}

.technicalEntryPost {
  padding-bottom: 40px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 40px;
  .entryHeader {
    margin-bottom: 30px;
    .lgTx {
      font-weight: 700;
      color: $baseColor;
      border-bottom: 2px solid #ccc;
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
    }
    time {
      color: $orangeColor;
      font-weight: 700;
    }
  }
  .entry {
    > section {
      margin-top: 40px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 24px;
      color: $baseColor;
      background: $lightBlueColor;
      padding: 10px 20px;
      margin-bottom: 1em;
    }
    p {
      + p {
        margin-top: 30px;
      }
    }
    img {
      height: auto;
    }
    .alignleft {
      margin: 0 40px 20px 0;
    }
  }
}

.previousNext {
  margin-bottom: $baseSpace;
  a {
    font-size: 18px;
    display: block;
    text-align: center;
    position: relative;
    padding: 20px 0;
  }
  ul {
    li {
      flex: 1;
      margin: 0 15px;
    }
    a {
      border: 2px solid $baseColor;
      color: $baseColor;
      &:hover {
        background: $baseColor;
        color: #fff;
        &::before {
          border-top-color: #fff;
          border-right-color: #fff;
        }
      }
    }
  }
  .previous {
    a {
      &::before {
        @include beforeAfter(8px, 8px);
        border-top: 2px solid $baseColor;
        border-right: 2px solid $baseColor;
        transform: rotate(-135deg);
        position: absolute;
        left: 15px;
        top: calc(50% - 4px);
        transition: $ts;
      }
    }
  }
  .next {
    a {
      &::before {
        @include beforeAfter(8px, 8px);
        border-top: 2px solid $baseColor;
        border-right: 2px solid $baseColor;
        transform: rotate(45deg);
        position: absolute;
        right: 15px;
        top: calc(50% - 4px);
        transition: $ts;
      }
    }
  }
  .back {
    margin: 30px auto 0;
    width: 80%;
    max-width: 500px;
    &:hover {
      opacity: 0.7;
    }
    &::before {
      @include beforeAfter(8px, 8px);
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(-135deg);
      position: absolute;
      left: 15px;
      top: calc(50% - 4px);
      transition: $ts;
    }
  }
}

// **************************************************
//	product
// **************************************************
#productHeader {
  background-image: url(../img/header_products_min.jpg);
}

#productContents {
  padding-top: 60px;
  #newProduct {
    margin-top: 20px;
  }
  #products {
    margin-top: 0;
  }
  .productLink {
    background: $orangeColor;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    width: 80%;
    max-width: 500px;
    margin: 0 auto 60px;
    display: block;
    text-align: center;
    line-height: 1.4;
    padding: 20px 0;
    position: relative;
    &:hover {
      opacity: 0.7;
    }
    &::before {
      @include beforeAfter(27px, 27px);
      background: url(../img/blank.png) no-repeat center bottom;
      position: absolute;
      right: 12px;
      bottom: 12px;
      background-size: 27px auto;
    }
  }
}

.productEntryPost {
  margin-top: -50px;
  .entryHeader {
    h3 {
      text-align: center;
      font-size: 36px;
      color: $baseColor;
      letter-spacing: 0.1em;
      margin: 0.5em 0;
      line-height: 1.2;
    }
  }
  .entry {
    section {
      margin-bottom: 60px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 500;
      margin-bottom: 1em;
    }
    p {
      &:empty {
        display: none;
      }
      + p {
        margin-top: 2em;
      }
    }
    table {
      margin-bottom: 20px;
    }
    th,
    td {
      color: $baseColor;
    }
    th {
      font-weight: normal;
      background: $lightBlueColor;
    }
  }
  img {
    height: auto;
  }
}

.productTaxonomyDesc {
  align-items: center;
  margin-top: 0;
  .tx {
    text-align: center;
  }
  h3 {
    // font-size: 41px;
    font-size: 28px;
    letter-spacing: 0.1em;
    margin-bottom: 0.5em;
  }
}

.taxonomyEntryList {
  flex-wrap: wrap;
  margin: 0 -20px 40px;
  text-align: center;
  li {
    padding: 0 20px;
    width: calc(100% / 3);
    margin-bottom: 40px;
  }
  a {
    display: block;
    &:hover {
      opacity: 0.7;
    }
  }
  h4 {
    font-size: 24px;
    margin: 0.25em 0 0.5em;
  }
}

.postid-466,
.postid-469,
.postid-477 {
  .entryHeader {
    display: none;
  }
}

.postid-469 {
  .card {
    text-align: center;
    margin-bottom: 60px;
    img {
      height: auto;
    }
  }
}

// **************************************************
//	guide
// **************************************************
#productsContents {
  section {
    section {
      margin: 40px 0 80px;
    }
  }
  .warning {
    li {
      background: $lightBlueColor;
      flex: 1;
      padding: 25px;
      flex-direction: column;
      align-items: center;
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
    img {
      width: 150px;
      height: auto;
      margin-bottom: 30px;
    }
  }
  .attention {
    section {
      &:nth-of-type(1) {
        .iconTtl {
          background-image: url(../img/icon_attention01.png);
        }
      }
      &:nth-of-type(2) {
        .iconTtl {
          background-image: url(../img/icon_attention02.png);
        }
      }
      &:nth-of-type(3) {
        .iconTtl {
          background-image: url(../img/icon_attention03.png);
        }
      }
      &:nth-of-type(4) {
        .iconTtl {
          background-image: url(../img/icon_attention04.png);
        }
      }
      &:nth-of-type(5) {
        .iconTtl {
          background-image: url(../img/icon_attention05.png);
        }
      }
    }
    ol {
      margin-left: 80px;
      counter-reset: number;
      > li {
        position: relative;
        padding-left: 40px;
        counter-increment: number;
        &:not(:last-child) {
          margin-bottom: 40px;
        }
        &::before {
          width: 25px;
          height: 25px;
          border-radius: 25px;
          background: $lightBlueColor;
          color: $baseColor;
          position: absolute;
          left: 0;
          top: 0;
          content: counter(number);
          text-align: center;
          font-weight: 700;
          line-height: 25px;
        }
      }
      ul {
        margin: 20px 0 0 40px;
      }
    }
  }
}

#aircargoContents {
  section {
    section {
      margin-top: 80px;
    }
  }
  .borderBottomTtl {
    font-weight: 700;
  }
  p {
    + p {
      margin-top: 30px;
    }
  }
}

#lineupContents {
  .lineupNav {
    margin-top: 40px;
    padding-bottom: 40px;
    li {
      flex: 1;
      flex-basis: 25%;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
    a {
      background: $baseColor;
      color: #fff;
      position: relative;
      padding: 30px 10px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before {
        @include beforeAfter(12px, 12px);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(135deg);
        position: absolute;
        bottom: 15px;
        left: calc(50% - 7px);
        transition: $ts;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    span {
      line-height: 1.2;
      font-size: 16px;
    }
  }
  section {
    section {
      margin: 40px 0 80px;
    }
  }
  .borderBottomTtl {
    font-weight: 700;
  }
  h5 {
    font-weight: 700;
    font-size: 19px;
    margin-bottom: 1em;
  }
  p {
    + p {
      margin-top: 20px;
    }
  }
  #lineup01 {
    .mediaImg {
      width: 270px;
      img {
        height: auto;
      }
    }
    .mediaTx {
      flex: 1;
      margin-left: 60px;
    }
    ul {
      margin-top: 40px;
    }
    li {
      flex-direction: column;
      flex: 1;
      &:not(:last-child) {
        margin-right: 30px;
      }
      > p {
        margin-top: 25px;
      }
    }
    .img {
      height: 145px;
      border: 1px solid #ccc;
      text-align: center;
      padding-top: 12px;
      img {
        width: auto;
        height: 120px;
        margin: 0 auto;
      }
    }
  }
  #lineup02 {
    > section {
      &:nth-of-type(2) {
        .mediaTx {
          order: 2;
        }
        .mediaImg {
          order: 1;
        }
      }
    }
    .flexHalf {
      > * {
        flex: 1;
        &:first-child {
          margin-right: 30px;
        }
      }
      .mediaTx {
        margin-top: 20px;
      }
    }
    .tabs {
      margin-top: 40px;
      input[name="tabItem"] {
        display: none;
      }
      .tabItem {
        cursor: pointer;
        transition: $ts;
      }
      .tabsBtn {
        justify-content: space-between;
      }
      label {
        flex: 1;
        color: $baseColor;
        font-size: 16px;
        font-weight: 700;
        padding: 20px 0;
        border: 2px solid $baseColor;
        text-align: center;
        &:not(:last-child) {
          margin-right: 10px;
        }
        &:hover {
          color: #fff;
          background: $baseColor;
        }
      }
      #tab01:checked ~ .tabsBtn [for="tab01"],
      #tab02:checked ~ .tabsBtn [for="tab02"],
      #tab03:checked ~ .tabsBtn [for="tab03"],
      #tab04:checked ~ .tabsBtn [for="tab04"],
      #tab05:checked ~ .tabsBtn [for="tab05"] {
        background: $baseColor;
        color: #fff;
      }
      .tabContent {
        display: none;
        > p {
          margin: 30px 0 15px;
        }
      }
      #tab01:checked ~ #tab01Content,
      #tab02:checked ~ #tab02Content,
      #tab03:checked ~ #tab03Content,
      #tab04:checked ~ #tab04Content,
      #tab05:checked ~ #tab05Content {
        display: block;
        animation: show 0.2s linear;
      }
      tr {
        &:nth-child(odd) {
          background: $lightBlueColor;
        }
      }
      .txLink {
        color: $baseColor;
      }
      th {
        font-size: 16px;
        color: #636363;
        line-height: 1.2;
      }
      td {
        padding: 15px;
        color: #636363;
      }
      .taLeft {
        text-align: left;
      }
    }
  }
  #lineup03 {
    img {
      width: 100%;
      max-width: 800px;
      height: auto;
      margin: 40px auto;
    }
  }
  #lineup04,
  #lineup05 {
    .scroll {
      margin: 40px 0 0;
    }
    th,
    td {
      padding: 15px 10px;
    }
    th {
      background: $lightBlueColor;
      color: $baseColor;
    }
    td {
      color: #636363;
      &:first-child {
        font-weight: 700;
      }
    }
  }
  #lineup05 {
    .scroll {
      margin-bottom: 40px;
    }
  }
}

// **************************************************
//	search
// **************************************************
#searchHeader {
  background: $lightBlueColor;
}

#searchContents {
  .inner {
    margin-top: 25px;
  }
  .searchResults {
    color: $baseColor;
  }
  .parentheses {
    &:first-child {
      position: relative;
      top: -5px;
      left: -3px;
    }
  }
  .bold {
    font-size: 18px;
    font-weight: 700;
  }
  dl {
    padding: 40px 0;
    border-bottom: 2px solid #ddd;
  }
  dt {
    a {
      font-size: 24px;
      color: $baseColor;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  dd {
    line-height: 1.4;
    margin-top: 10px;
  }
}

// **************************************************
//	news
// **************************************************
#newsHeader {
  background-image: url(../img/header_news_min.jpg);
}

#newsContents {
  .inner {
    margin-top: 0;
  }
  .postList {
    li {
      border-bottom: 1px solid #ccc;
    }
    a {
      padding: 30px 0 35px;
      display: block;
      &:hover {
        opacity: 0.7;
      }
    }
    time {
      color: $orangeColor;
      font-weight: 700;
    }
    h3 {
      font-size: 24px;
      color: $baseColor;
      margin: 0.25em 0 0.5em;
    }
    .more {
      display: inline-block;
      padding: 0.3em 3em;
      margin-top: 20px;
      position: relative;
      &::before {
        @include beforeAfter(8px, 8px);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(45deg);
        position: absolute;
        right: 20px;
        top: calc(50% - 4px);
        transition: $ts;
      }
    }
  }
  .newsEntryPost {
    margin-top: 30px;
    time {
      color: $orangeColor;
      font-weight: 700;
    }
    h3 {
      font-weight: 700;
      font-size: 30px;
      color: $baseColor;
      margin: 0.2em 0 0.5em;
      padding-bottom: 0.75em;
      border-bottom: 2px solid #ccc;
    }
  }
  .entry {
    > section {
      margin-top: 40px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 24px;
      color: $baseColor;
      background: $lightBlueColor;
      padding: 10px 20px;
      margin-bottom: 1em;
    }
    p {
      + p {
        margin-top: 30px;
      }
    }
    img {
      height: auto;
    }
    .alignleft {
      margin: 0 40px 20px 0;
    }
  }
  .previousNext {
    margin-top: 80px;
    padding-top: 40px;
    border-top: 1px solid #ccc;
  }
}

// **************************************************
//	applyContents
// **************************************************
#applyContents {
  .inner {
    max-width: 750px;
    margin-bottom: 90px;
  }
  form {
    dl {
      margin-bottom: 15px;
    }
    dt {
      width: 240px;
    }
    dd {
      flex: 1;
    }
    input,
    textarea {
      border: 1px solid #ccc;
      padding: 0.75em;
      width: 100%;
      border-radius: 3px;
      transition: $ts;
      &:focus {
        border: 1px solid $baseColor;
      }
    }
    .ttlBlueLineTable {
      + div {
        text-align: center;
      }
    }
    .contact-btn,
    .wpcf7c-btn-back {
      width: auto;
      padding: 30px 80px;
      display: inline-block;
      background: #37357d;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      transition: $ts;
      border: none;
      cursor: pointer;
      border-radius: 0;
      margin-top: 20px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .wpcf7-response-output {
    border-color: $vividBlueColor;
    color: $vividBlueColor;
    padding: 20px;
    background: rgba($vividBlueColor, 0.1);
  }
  .wpcf7-not-valid-tip {
    margin-top: 5px;
  }
}
